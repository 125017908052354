import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import WhiteBG from "../../../components/ui/WhiteBG";
import InputComponent from "../../../components/ui/Input";
import ButtonComponent from "../../../components/ui/Button";
import EditUser from "../EditUser";

export default function UserDetailContainer({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      w="100%"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      mt="1.5rem"
    >
      <WhiteBG>
        <Flex w="100%" align="center" direction="column" gap="2rem">
          <Flex
            w="100%"
            align="center"
            gap="1rem"
            wrap="wrap"
            justify="space-between"
          >
            <InputComponent label="First Name" value={user?.first_name || ""} />
            <InputComponent label="Last Name" value={user?.last_name || ""} />
          </Flex>
          <Flex
            wrap="wrap"
            w="100%"
            align="center"
            gap="1rem"
            justify="space-between"
          >
            <InputComponent label="Email" value={user?.email || ""} />
            <InputComponent
              label="Phone Number"
              value={user?.phone_number || ""}
            />
          </Flex>
          <Flex
            wrap="wrap"
            w="100%"
            align="center"
            gap="1rem"
            justify="space-between"
          >
            <InputComponent
              label="Designation"
              value={user?.designation || ""}
            />
            <InputComponent
              label="Directorate"
              value={user?.directorate || ""}
            />
          </Flex>
          <Flex
            wrap="wrap"
            w="100%"
            align="center"
            gap="1rem"
            justify="space-between"
          >
            <InputComponent label="Role" value={user?.user_role || ""} />
            <InputComponent label="Member ID" value={user?.member_id || ""} />
          </Flex>
        </Flex>
        <Flex mt="3rem" gap="1rem" w="100%" align="center" justify="end">
          <ButtonComponent onClick={onOpen} title="Edit User" />
        </Flex>
      </WhiteBG>
      <EditUser  isOpen={isOpen} onClose={onClose} user={user} />
    </Box>
  );
}
