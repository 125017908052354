import React from "react";
import ReusableTitle from "../../components/ui/ReusableTitle";

export default function PastorUserTitle() {
  return (
    <ReusableTitle
      text1="NAME"
      text2="EMAIL"
      text3="DEPARTMENT"
      text4="DESIGNATION"
      text5="PHONE"
    />
  );
}
