import axios from "axios";

const API_URL = "https://reportdash.ehcc.tech/api";

export async function signin(data) {
  const response = await axios.post(`${API_URL}/user/login`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
}

export async function getDashboard(token) {
  const response = await axios.get(`${API_URL}/user/dashboard`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function getPastorDashboard(token) {
  const response = await axios.get(`${API_URL}/pastor/dashboard`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function createReport(data, token) {
  const response = await axios.post(`${API_URL}/user/report`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function createUser(data, token) {
  const response = await axios.post(`${API_URL}/admin/users`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function getAllUsers(token) {
  const response = await axios.get(`${API_URL}/admin/users`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function getUsers(token, id) {
  const response = await axios.get(`${API_URL}/admin/users/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function adminReports(token) {
  const response = await axios.get(`${API_URL}/admin/dashboard`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function viewReports(token, id) {
  const response = await axios.get(`${API_URL}/reports/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function addComment(token, data) {
  const response = await axios.post(`${API_URL}/comments`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function editProfile(token, data, id) {
  const response = await axios.patch(`${API_URL}/user/profile/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function editUserAdmin(token, data, id) {
  const response = await axios.patch(`${API_URL}/admin/users/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function logout(token) {
  const response = await axios.post(
    `${API_URL}/logout`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}

export async function forgotPassword(data) {
  const response = await axios.post(`${API_URL}/forget-password`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
}

export async function resetPassword(data) {
  const response = await axios.post(`${API_URL}/reset-password`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
}

export async function getDirectorate(token) {
  const response = await axios.get(`${API_URL}/directorates`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function getDepartment(token, directorate) {
  const response = await axios.get(`${API_URL}/directorates/${directorate}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function approveReport(token, data, id) {
  const response = await axios.patch(`${API_URL}/reports/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function updatePassword(token, data, id) {
  const response = await axios.patch(
    `${API_URL}/user/update-password/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}
