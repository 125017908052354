import React from "react";
import calender from "../../images/Calendar.png";
import {
  Flex,
  Spacer,
  Heading,
  Box,
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuItem,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { getDashboard } from "../../util/http";
import ReusableSubmissionHeader from "../ui/ReusableSubmissionHeader";

export default function SubmissionHeader({ setReport }) {
  const token = localStorage.getItem("token");

  async function filterPending() {
    const response = await getDashboard(token);
    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "pending"
    );
    setReport(filteredItem);
  }

  async function filterApproved() {
    const response = await getDashboard(token);
    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "approved"
    );
    setReport(filteredItem);
  }

  async function filterQueried() {
    const response = await getDashboard(token);
    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "queried"
    );

    setReport(filteredItem);
  }
  return (
    <ReusableSubmissionHeader
      filterApproved={filterApproved}
      filterPending={filterPending}
      filterQueried={filterQueried}
    />
  );
}
