import React, { useEffect, useState } from "react";
import WhiteBG from "../../components/ui/WhiteBG";
import { Box } from "@chakra-ui/react";
import UserFilter from "./UserFilter";
import UserTitle from "./UserTitle";
import UserList from "./UserList";
import ReportFooter from "../../components/report/ReportFooter";
import { getAllUsers } from "../../util/http";

export default function UserContainer({ setProgress }) {
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("token");
  const [slicedData, setSlicedData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getAllUsers(token);
        setProgress(80);
        setProgress(100);
        setUsers(response.users.reverse());
      } catch (error) {
        setProgress(100);
        // console.log(error);
      }
    }
    fetchData();
  }, []);

  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    setTotalUsers(users?.length);
  }, [users]);

  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage, setuserPerPage] = useState(10);

  const lastuserIndex = currentPage * userPerPage;
  const firstuserIndex = lastuserIndex - userPerPage;

  useEffect(() => {
    const userData = users?.slice(firstuserIndex, lastuserIndex);
    setSlicedData(userData);
  }, [firstuserIndex, lastuserIndex, users]);

  return (
    <Box
      w="100%"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      mt="1.5rem"
    >
      <WhiteBG>
        <UserFilter setUsers={setUsers} />
        <Box mt="1.5rem">
          <UserTitle />
          <Box mt="1.5rem">
            <UserList users={slicedData} />
            <ReportFooter
              total={totalUsers}
              itemPerPage={userPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setItem={setSlicedData}
              items={users}
              setItemPerPage={setuserPerPage}
            />
          </Box>
        </Box>
      </WhiteBG>
    </Box>
  );
}
