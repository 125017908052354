import React, { Fragment, useState } from "react";
import { Flex, useDisclosure } from "@chakra-ui/react";
import UserItem from "./UserItem";
import EditUser from "./EditUser";

export default function UserList({ users }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userId, setUserId] = useState("");
  const user = users?.find((el) => el.id === userId);

  return (
    <Fragment>
      <Flex direction="column" h="600px">
        {users?.map((item, i) => (
          <UserItem
            setUserId={setUserId}
            onOpen={onOpen}
            isOpen={isOpen}
            onClose={onClose}
            user={user}
            key={i}
            item={item}
          />
        ))}
      </Flex>
    </Fragment>
    // <EditUser
    //   user={user}
    //   isOpen={isOpen}
    //   onClose={onClose}
    //   onOpen={onOpen}
    // />
  );
}
