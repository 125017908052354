import React from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import PastorProfileContainer from "./PastorProfileContainer";

export default function PastorProfile() {
  return (
    <DashboardReusable
      dashboard="pastor-dashboard"
      report="pastor-report"
      user="pastor-user"
      profile="pastor-profile"
      show={true}
    >
      <ReusableSubHeader text="Profile" />
      <PastorProfileContainer />
    </DashboardReusable>
  );
}
