import React, { Fragment, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Flex,
  Input,
  Button,
  Text,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";

import AuthComponent from "../ui/AuthComponent";
import PasswordInput from "../ui/PasswordInput";
import Loader from "../ui/Loader";
import { signin } from "../../util/http";
import { AuthContext } from "../../util/authContext";

export default function Login() {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(true);

  const [membershipID, setMembershipID] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const toast = useToast();

  const ctx = useContext(AuthContext);

  function toggleHandler() {
    if (type === "password") {
      setType("text");
      setIcon(false);
    } else {
      setType("password");
      setIcon(true);
    }
  }

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }

  async function loginHandler() {
    if (!membershipID && !password) {
      showToast("Invalid inputs", "Login details cannot be empty", "error");
      return;
    }
    if (!membershipID || !password) {
      showToast("Invalid inputs", "Login detail is missing", "error");
      return;
    }
    const data = {
      member_id: membershipID,
      password,
    };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await signin(data);
      // console.log(response);
      ctx.setUser(response?.user);
      localStorage.setItem("user", JSON.stringify(response?.user));

      ctx.login(response.token);
      if (response?.user?.user_role?.toLowerCase() === "member") {
        navigate("/");
      } else if (
        response?.user?.user_role?.toLowerCase() === "directorate" ||
        response?.user?.user_role?.toLowerCase() === "pastor"
      ) {
        navigate("/directorate-dashboard");
      }
      localStorage.setItem("token", response.token);
      localStorage.setItem("memberID", response.user.id);
      localStorage.setItem("role", response?.user?.user_role);

      showToast("Login success", "", "success");
      setLoading(false);
      setProgress(80);
      setProgress(100);
    } catch (error) {
      // console.log(error);
      setProgress(100);
      setLoading(false);
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent
        text="Don't have an Admin Account?"
        path="login"
        linkText="Contact your Directorate"
        heading="EHCC Department Login"
      >
        <form className="w-[90%] lg:w-[400px] mx-auto flex flex-col gap-4">
          <FormControl isRequired>
            <FormLabel fontWeight="light" htmlFor="username">
              Membership ID
            </FormLabel>
            <Input
              size="md"
              focusBorderColor="#481053"
              borderRadius="14px"
              variant="outline"
              type="text"
              id="username"
              placeholder="Enter Membership ID"
              value={membershipID}
              onChange={(e) => setMembershipID(e.target.value)}
            />
          </FormControl>
          <PasswordInput
            type={type}
            icon={icon}
            toggleHandler={toggleHandler}
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Flex w="100%" alignItems="center" justify="space-between">
            <Button
              w="100%"
              bg="brand.900"
              color="white"
              borderRadius="14px"
              variant="solid"
              isLoading={loading}
              loadingText="Processing"
              onClick={loginHandler}
            >
              Login
            </Button>
          </Flex>
          <Flex alignItems="end" justify="end" w="100%">
            <Text fontSize="12px" fontWeight="light" color="#828282">
              Forgot Password?{" "}
              <Link className="text-[#481053]" to="/forgot-password">
                Click Here
              </Link>
            </Text>
          </Flex>
        </form>
      </AuthComponent>
    </Fragment>
  );
}
