import React from "react";
import ReusableSubmissionHeader from "../../components/ui/ReusableSubmissionHeader";
import { adminReports } from "../../util/http";

export default function DirectorateSubmissionHeader({ setReport }) {
  const token = localStorage.getItem("token");

  async function filterPending() {
    const response = await adminReports(token);
    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "pending"
    );
    setReport(filteredItem);
  }

  async function filterApproved() {
    const response = await adminReports(token);
    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "approved"
    );
    setReport(filteredItem);
  }

  async function filterQueried() {
    const response = await adminReports(token);
    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "queried"
    );

    setReport(filteredItem);
  }
  return (
    <ReusableSubmissionHeader
      filterApproved={filterApproved}
      filterPending={filterPending}
      filterQueried={filterQueried}
    />
  );
}
