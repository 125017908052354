import React from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import PastorReportContainer from "./PastorReportContainer";

export default function PastorReport() {
  return (
    <DashboardReusable
      dashboard="pastor-dashboard"
      report="pastor-report"
      user="pastor-user"
      profile="pastor-profile"
      show={true}
    >
      <ReusableSubHeader text="Reports" />
      <PastorReportContainer />
    </DashboardReusable>
  );
}
