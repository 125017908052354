import React from "react";
import ReusableUserForm from "../../directorate/ui/ReusableUserForm";

export default function PastorCreateUserForm({
  firstName,
  lastName,
  setFirstName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  setDepartment,
  setDesignation,
  setImage,
}) {
  return (
    <ReusableUserForm
      firstName={firstName}
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      setImage={setImage}
      setDepartment={setDepartment}
      setDesignation={setDesignation}
    />
  );
}
