import React from "react";
import { Text } from "@chakra-ui/react";

export default function SubmissionText({
  text,
  width,
  fw,
  color,
  align,
  display,
  capitalize,
}) {
  return (
    <Text
      color={color ? color : "#3F434A"}
      fontSize={{ base: "10px", md: "10px", lg: "16px" }}
      textAlign={align ? align : "center"}
      fontWeight={fw ? fw : "normal"}
      w={width ? width : ""}
      style={{ textTransform: capitalize ? capitalize : "capitalize" }}
      display={display ? display : "block"}
    >
      {text}
    </Text>
  );
}
