import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";

export default function AddRowBtn({
  onClick,
  title,
  variant,
  bg,
  color,
  disabled,
}) {
  return (
    <Box className="mt-[-1rem]">
      <Flex align="end" justify="end">
        <Button
          type="button"
          bg={bg ? bg : "transparent"}
          variant={variant ? variant : "outline"}
          colorScheme="purple"
          color={color ? color : "brand.900"}
          border="1px solid purple"
          fontWeight="medium"
          fontSize="15px"
          py="0.5rem"
          px="1.5rem"
          _hover={{ opacity: "80%" }}
          borderRadius="0.75rem"
          onClick={onClick}
          isDisabled={disabled ? disabled : false}
        >
          {title ? title : "Add Row"}
        </Button>
      </Flex>
    </Box>
  );
}
