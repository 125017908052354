import React, { Fragment } from "react";
import Dashboard from "../components/dashboard/Dashboard";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import LoginPage from "./LoginPage";

export default function DashboardPage() {
  const ctx = useContext(AuthContext);

  return <Fragment>{ctx.isLoggedIn ? <Dashboard /> : <LoginPage />}</Fragment>;
}
