import React from "react";
import { Flex } from "@chakra-ui/react";
import ReusableItem from "./ReusableItem";

export default function ReusableList({ data, user }) {
  return (
    <Flex direction="column" h="100%">
      {data?.map((item, i) => (
        <ReusableItem user={user} key={i} item={item} />
      ))}
    </Flex>
  );
}
