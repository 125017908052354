import React from "react";
import TotalReport from "./TotalReport";
import PendingReports from "./PendingReports";
import QueriedReport from "./QueriedReport";
import ApprovedReports from "./ApprovedReports";
import { SimpleGrid } from "@chakra-ui/react";

export default function DashboardReport({ report }) {
  return (
    <SimpleGrid
      spacing={4}
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      templateColumns={{
        base: "repeat(auto-fill, minmax(45%, 1fr))",
        md: "repeat(auto-fill, minmax(45%, 1fr))",
        lg: "repeat(auto-fill, minmax(22%, 1fr))",
      }}
    >
      <TotalReport report={report.count} />
      <PendingReports report={report.pending_reports} />
      <QueriedReport report={report.queried_reports} />
      <ApprovedReports report={report.approved_reports} />
    </SimpleGrid>
  );
}
