import React from "react";
import { Box, Heading } from "@chakra-ui/react";

export default function DetailHeader({ text }) {
  return (
    <Box w="100%">
      <Heading as="h2" fontSize="18px" fontWeight="medium" color="#3F434A">
        {text}
      </Heading>
    </Box>
  );
}
