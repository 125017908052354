import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";
import Form from "../ui/Form";
import PasswordInput from "../ui/PasswordInput";
import ButtonComponent from "../ui/Button";
import PasswordValidation from "../../validation/PasswordValidation";
import PasswordValidator from "../../validation/PasswordValidator";
import { useNavigate } from "react-router-dom";

import {
  validateIncludes,
  validateLength,
  validateLetter,
  validateStrength,
} from "../../util/validator";

import Loader from "../ui/Loader";
import { AuthContext } from "../../util/authContext";
import { updatePassword } from "../../util/http";

export default function Security() {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(true);
  // const ctx = useContext(AuthContext);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isUppercase, setIsUppercase] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isEightChar, setIsEightChar] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);

  const toast = useToast();
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 5000,
      isClosable: true,
    });
  }

  const user = ctx.user;

  function confirmPasswordHandler(e) {
    setConfirmPassword(e.target.value);
  }

  function passwordHandler(e) {
    setShowValidation(true);
    const pass = e.target.value;

    // password length
    if (validateLength(pass)) {
      setIsEightChar(true);
    } else {
      setIsEightChar(false);
    }

    if (validateLetter(pass)) {
      // setIsNumber(true);
      setIsUppercase(true);
    } else {
      // setIsNumber(false);
      setIsUppercase(false);
    }

    // password contain special characters
    if (validateIncludes(pass, "@")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "!")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "#")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "$")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "+")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "-")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "%")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "^")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "_")) {
      setIsSymbol(true);
    } else {
      setIsSymbol(false);
    }

    if (validateStrength(pass) < 20) {
      setError(true);
      setFormIsValid(false);
    } else if (validateStrength(pass) > 20 && validateStrength(pass) <= 40) {
      setError(true);
      setWarning(true);
      setFormIsValid(false);
    } else if (validateStrength(pass) > 40) {
      setError(true);
      setWarning(true);
      setSuccess(true);
      setNewPassword(pass);
      setFormIsValid(true);
    } else {
      setError(true);
      setWarning(false);
      setSuccess(false);
      setFormIsValid(false);
    }
  }

  useEffect(() => {
    if (newPassword !== confirmPassword && oldPassword === "") {
      setFormIsValid(false);
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
      setFormIsValid(true);
    }
  }, [confirmPassword, newPassword, oldPassword]);

  async function updatePasswordHandler() {
    if (!oldPassword) {
      showToast("Old password field cannot be empty", "", "error");
      return;
    }
    if (!newPassword && !confirmPassword) {
      showToast("Passwords fields cannot be empty", "", "error");
      setPasswordMatch(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      showToast("Passwords do not match", "", "error");
      setPasswordMatch(false);
      return;
    }
    const data = {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await updatePassword(token, data, user.id);

      showToast("Password updated successfully", "", "success");
      navigate("/login");
      ctx.logout();
      setProgress(80);
      setProgress(100);
    } catch (error) {
      setLoading(false);
      setProgress(100);
      showToast(
        error?.response?.data.message || "Something went wrong",
        "",
        "error"
      );
      // console.log(error);
    }
  }

  function toggleHandler() {
    if (type === "password") {
      setType("text");
      setIcon(false);
    } else {
      setType("password");
      setIcon(true);
    }
  }
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <Box w={{ lg: "40%", base: "100%", md: "100%" }} py="2rem">
        <Form>
          <PasswordInput
            toggleHandler={toggleHandler}
            label="Old Password"
            type={type}
            icon={icon}
            placeholder="************************"
            onChange={(e) => setOldPassword(e.target.value)}
          />

          <PasswordInput
            toggleHandler={toggleHandler}
            label="New Password"
            type={type}
            icon={icon}
            placeholder="************************"
            onChange={passwordHandler}
          />

          <PasswordInput
            toggleHandler={toggleHandler}
            label="Confirm New Password"
            type={type}
            icon={icon}
            placeholder="************************"
            onChange={confirmPasswordHandler}
            isInvalid={!passwordMatch}
          />
          {showValidation && (
            <PasswordValidator
              error={error}
              warning={warning}
              success={success}
            />
          )}
          {showValidation && (
            <PasswordValidation
              isEightChar={isEightChar}
              isSymbol={isSymbol}
              isUppercase={isUppercase}
            />
          )}
          <Flex align="center" justify="end">
            <ButtonComponent
              onClick={updatePasswordHandler}
              disabled={!formIsValid}
              title="Update Password"
              isLoading={loading}
              loadText="Updating"
            />
          </Flex>
        </Form>
      </Box>
    </Fragment>
  );
}
