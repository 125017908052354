import React from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import DirectorateReportContainer from "./DirectorateReportContainer";
import { Fragment } from "react";
import Loader from "../../components/ui/Loader";
import { useState } from "react";

export default function Report() {
  const [progress, setProgress] = useState(0);
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
        path="directorate-profile"
      >
        <ReusableSubHeader text="Reports" />
        <DirectorateReportContainer setProgress={setProgress} />
      </DashboardReusable>
    </Fragment>
  );
}
