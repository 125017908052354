import React from "react";
import { Textarea, FormLabel, Flex, FormControl } from "@chakra-ui/react";
import ReusableReplyCommentBtn from "./ReusableReplyCommentBtn";
import { AddIcon } from "@chakra-ui/icons";

export default function ReusableTextarea({
  value,
  onChange,
  label,
  register,
  visible,
  onClick,
  readOnly,
  bg,
  required = false
}) {

  return (
    <FormControl isRequired={required}>
      <Flex w="100%" align="center" justify="space-between">
        <FormLabel
          style={{ textTransform: "uppercase" }}
          fontSize="14px"
          fontWeight="normal"
          h="20px"
          color="#515262"
        >
          {label}
        </FormLabel>
      </Flex>
      <Textarea
        size="md"
        variant="outline"
        border="1px solid #ccc"
        focusBorderColor="#481053"
        borderRadius="14px"
        placeholder="Enter Text Here"
        value={value}
        onChange={onChange}
        className="input"
        {...register}
        bg={bg ? "white" : "white"}
        readOnly={readOnly ? false : true}
        
      />
    </FormControl>
  );
}
