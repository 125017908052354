import React from "react";
import AuthComponent from "../ui/AuthComponent";
import { Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function CheckYourEmail() {
  return (
    <AuthComponent>
      <Flex align="center" justify="center">
        <Flex align="center" direction="column" gap="2rem">
          <Text
            fontSize="20px"
            color="brand.900"
            fontWeight="medium"
            textAlign="start"
            width="60%"
            mx="auto"
          >
            Password reset email has been sent, check your email to complete
            password reset...
          </Text>
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to="/login"
          >
            Back to login
          </Link>
        </Flex>
      </Flex>
    </AuthComponent>
  );
}
