import React from "react";
import DashboardReusable from "../ui/DashboardReusable";
import SubHeader from "./SubHeader";
import ReportContainer from "./ReportContainer";
import { Fragment } from "react";
import Loader from "../ui/Loader";
import { useState } from "react";

export default function Report() {
  const [progress, setProgress] = useState(0);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable path="profile">
        <SubHeader />
        <ReportContainer setProgress={setProgress} />
      </DashboardReusable>
    </Fragment>
  );
}
