import React, { useEffect, useState } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import ProfileContainer from "../../components/profile/ProfileContainer";
import { getUsers } from "../../util/http";
import { Fragment } from "react";
import Loader from "../../components/ui/Loader";

export default function Profile() {
  const token = localStorage.getItem("token");
  const [progress, setProgress] = useState(0);
  const id = localStorage.getItem("memberID");
  const [user, setUser] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getUsers(token, id);
        setProgress(80);
        setProgress(100);
        setUser(response.user[0]);
      } catch (error) {
        setProgress(100);
      }
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
        path="directorate-profile"
      >
        <ReusableSubHeader text="Profile" />
        <ProfileContainer user={user} />
      </DashboardReusable>
    </Fragment>
  );
}
