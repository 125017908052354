import React from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import DashboardReport from "./DashboardReport";
import { Box } from "@chakra-ui/react";
import DashboardSubmission from "./DashboardSubmission";
import { useEffect } from "react";
import { adminReports, getPastorDashboard } from "../../util/http";
import { useState } from "react";
import { Fragment } from "react";
import Loader from "../../components/ui/Loader";
import { sortData } from "../../util/lib";

export default function DirectorateDashboard() {
  const [reports, setReports] = useState("");
  const [allReports, setAllReports] = useState([]);
  const [progress, setProgress] = useState(0);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(() => {
    async function fetchData() {
      try {
        let response;
        setProgress(20);
        setProgress(40);
        setProgress(60);
        if (role.toLowerCase() === "directorate") {
          response = await adminReports(token);
        } else if (role.toLowerCase() === "pastor") {
          response = await getPastorDashboard(token);
        }
        setProgress(80);
        setProgress(100);
        const result = sortData(response?.reports);
        setAllReports(result.reverse());
        setReports(response);
      } catch (error) {
        setProgress(100);
      }
    }
    fetchData();
  }, [token, role]);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
        path="directorate-profile"
      >
        <ReusableSubHeader text="Dashboard" />
        <Box mt="1.5rem">
          <DashboardReport report={reports} />
        </Box>
        <DashboardSubmission
          reports={allReports}
          setAllReports={setAllReports}
        />
      </DashboardReusable>
    </Fragment>
  );
}
