import React from "react";
import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  FormHelperText,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function PasswordInput({
  type,
  password,
  setPassword,
  onChange,
  toggleHandler,
  icon,
  label,
  placeholder,
  isInvalid,
}) {
  return (
    <FormControl isRequired>
      <FormLabel fontWeight="light" htmlFor="password">
        {label}
      </FormLabel>

      <InputGroup border="1px solid #ccc" borderRadius="14px" size="md">
        <Input
          border="none"
          focusBorderColor="#481053"
          borderRadius="14px"
          pr="4.5rem"
          type={type}
          placeholder={placeholder ? placeholder : "Enter password"}
          value={password}
          onChange={onChange}
          isInvalid={isInvalid ? isInvalid : false}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            bg="transparent"
            size="sm"
            onClick={toggleHandler}
          >
            {icon ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
      {isInvalid && (
        <FormHelperText color="error.900" fontSize="14px">
          Password do not match
        </FormHelperText>
      )}
    </FormControl>
  );
}
