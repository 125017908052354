import React from "react";

export default function PasswordValidation({
  isEightChar,
  isUppercase,
  isSymbol,
  isEqual,
}) {
  return (
    <div className="w-full flex flex-col space-y-1">
      <div className="flex rounded-full w-full items-center space-x-4">
        <p
          className={`w-[5px] h-[5px] ${
            isEightChar ? "bg-green-500" : "bg-[#CECECE]"
          } `}
        ></p>
        <p
          className={`text-sm ${
            isEightChar ? "text-green-500" : "text-[#CECECE]"
          } text-start font-bold`}
        >
          Use 8 or more characters
        </p>
      </div>
      <div className="flex rounded-full w-full items-center space-x-4">
        <p
          className={`w-[5px] h-[5px]  ${
            isUppercase ? "bg-green-500" : "bg-[#CECECE]"
          }`}
        ></p>
        <p
          className={`text-sm  ${
            isUppercase ? "text-green-500" : "text-[#CECECE]"
          } text-start font-bold`}
        >
          Use uppercase, lowercase letters and numbers (e.g. Aa09)
        </p>
      </div>
      <div className="flex rounded-full w-full items-center space-x-4">
        <p
          className={`w-[5px] h-[5px]  ${
            isSymbol ? "bg-green-500" : "bg-[#CECECE]"
          }`}
        ></p>
        <p
          className={`text-sm  ${
            isSymbol ? "text-green-500" : "text-[#CECECE]"
          } text-start font-bold`}
        >
          Use a symbol (e.g. !@#$)
        </p>
      </div>

      {false && (
        <div className="flex rounded-full w-full items-center space-x-4">
          <p
            className={`w-[5px] h-[5px]  ${
              isEqual ? "bg-green-500" : "bg-[#CECECE]"
            }`}
          ></p>
          <p
            className={`text-sm ${
              isEqual ? "text-green-500" : "text-[#CECECE]"
            } text-start font-bold`}
          >
            Password match
          </p>
        </div>
      )}
    </div>
  );
}
