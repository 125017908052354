import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import SubmitReportPage from "./pages/SubmitReportPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import ReportPage from "./pages/ReportPage";
import ProfilePage from "./pages/ProfilePage";
import ReportDetailPage from "./pages/ReportDetailPage";
import DirectorateDashboardPage from "./pages/DirectorateDashboardPage";
import DirectorateReportPage from "./pages/DirectorateReportPage";
import DirectorateProfilePage from "./pages/DirectorateProfilePage";
import DirectorateReportDetailPage from "./pages/DirectorateReportDetailPage";
import DirectorateUserPage from "./pages/DirectorateUserPage";
import PastorDashboardPage from "./pages/PastorDashboardPage";
import PastorReportPage from "./pages/PastorReportPage";
import PastorUserPage from "./pages/PastorUserPage";
import PastorProfilePage from "./pages/PastorProfilePage";
import PastorDetailPage from "./pages/PastorDetailPage";
import NotFound from "./pages/NotFoundPage";
import ConfirmReset from "./pages/ConfirmReset";
import UserDetailPage from "./pages/UserDetailPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/new-password" element={<NewPasswordPage />} />
      <Route path="/confirm-email" element={<ConfirmReset />} />
      <Route path="/report/submit-report" element={<SubmitReportPage />} />
      <Route path="/report" element={<ReportPage />} />
      <Route path="/report/:id" element={<ReportDetailPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route
        path="/directorate-dashboard"
        element={<DirectorateDashboardPage />}
      />
      <Route path="/directorate-report" element={<DirectorateReportPage />} />
      <Route
        path="/directorate-report/:id"
        element={<DirectorateReportDetailPage />}
      />
      <Route path="/directorate-profile" element={<DirectorateProfilePage />} />
      <Route path="/directorate-user" element={<DirectorateUserPage />} />
      <Route path="/directorate-user/:id" element={<UserDetailPage />} />
      <Route path="/pastor-dashboard" element={<PastorDashboardPage />} />
      <Route path="/pastor-report" element={<PastorReportPage />} />
      <Route path="/pastor-report/:id" element={<PastorDetailPage />} />
      <Route path="/pastor-user" element={<PastorUserPage />} />
      <Route path="/pastor-profile" element={<PastorProfilePage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
