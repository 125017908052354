import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import WhiteBG from "../../components/ui/WhiteBG";
import ReusableTitle from "../../components/ui/ReusableTitle";
import ReportList from "./ReportList";
import ReportFooter from "../../components/report/ReportFooter";
import { adminReports, getPastorDashboard } from "../../util/http";
import DirectorateReportFilter from "../dashboard/DirectorateReportFilter";
import { sortData } from "../../util/lib";

export default function DirectorateReportContainer({ setProgress }) {
  const [reports, setReports] = useState([]);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [slicedReport, setSlicedReport] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        let response;
        if (role.toLowerCase() === "directorate") {
          response = await adminReports(token);
        } else {
          response = await getPastorDashboard(token);
        }
        setProgress(80);
        setProgress(100);
        const result = sortData(response?.reports);
        setReports(result.reverse());
      } catch (error) {
        setProgress(100);
      }
    }
    fetchData();
  }, []);

  const [totalReport, setTotalReport] = useState(0);

  useEffect(() => {
    setTotalReport(reports.length);
  }, [reports]);

  const [currentPage, setCurrentPage] = useState(1);
  const [reportPerPage, setreportPerPage] = useState(10);

  const lastreportIndex = currentPage * reportPerPage;
  const firstreportIndex = lastreportIndex - reportPerPage;

  useEffect(() => {
    const reportData = reports.slice(firstreportIndex, lastreportIndex);
    setSlicedReport(reportData);
  }, [firstreportIndex, lastreportIndex, reports]);

  return (
    <Box
      w="100%"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      mt="1.5rem"
    >
      <WhiteBG>
        <DirectorateReportFilter setReport={setReports} />
        <Flex
          w="100%"
          direction="column"
          gap="1.5rem"
          p={{ base: "0.5rem", md: "0.5rem", lg: "1.5rem" }}
          // maxHeight="650px"
        >
          <ReusableTitle
            text1="NAME"
            text2="TIME / DATE"
            text3="DEPARTMENT"
            text4="STATUS"
            text5="DESIGNATION"
          />
          <ReportList reports={slicedReport} />
          <ReportFooter
            total={totalReport}
            setItemPerPage={setreportPerPage}
            itemPerPage={reportPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setItem={setSlicedReport}
            items={reports}
          />
        </Flex>
      </WhiteBG>
    </Box>
  );
}
