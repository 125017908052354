import React from "react";
import ReportDetail from "../components/report-detail/ReportDetail";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ReportDetailPage() {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
      return;
    }
  }, [ctx.isLoggedIn, navigate]);
  return <ReportDetail />;
}
