import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import ButtonComponent from "../../components/ui/Button";

export default function ReusableCreateUser({
  isOpen,
  onClose,
  btnRef,
  children,
  onClick,
}) {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent w="100%">
        <DrawerCloseButton />

        <DrawerBody p="2rem">{children}</DrawerBody>

        <DrawerFooter>
          <ButtonComponent onClick={onClick} title="Create User" />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
