import { Button, Flex } from "@chakra-ui/react";
import React from "react";

export default function ReusableReplyCommentBtn({ title, onClick }) {
  return (
    <Flex align="end" justify="end">
      <Button
        onClick={onClick}
        color="brand.900"
        variant="ghost"
        colorScheme="purple"
      >
        {title}
      </Button>
    </Flex>
  );
}
