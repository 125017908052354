import React, { Fragment, useState } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import DirectorateReportDetailContainer from "./DirectorateReportDetailContainer";

import { Box } from "@chakra-ui/react";
import Loader from "../../components/ui/Loader";

export default function DirectorateReportDetail() {
  const [progress, setProgress] = useState(0);
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
      >
        <Box h="115vh" overflow="scroll">
          <ReusableSubHeader text="Technology Department" />
          <DirectorateReportDetailContainer setProgress={setProgress} />
        </Box>
      </DashboardReusable>
    </Fragment>
  );
}
