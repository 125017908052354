import { createContext, useState } from "react";

export const AuthContext = createContext({
  token: "",
  login: (token) => {},
  logout: () => {},
  isLoggedIn: "",
  user: "",
  setUser: (user) => {},
});

const  user = localStorage.getItem("user")

const userLocal =  user !== undefined && user !== 'undefined' ? JSON.parse(user)  : "";
export default function AuthProvider({ children }) {
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);
  const [userData, setUserData] = useState(userLocal);

  const isLoggedIn = !!token;

  function login(token) {
    setToken(token);
  }
  function logout() {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("memberID");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
  }

  function setUser(user) {
    setUserData(user);
  }

  const value = {
    token,
    login,
    logout,
    isLoggedIn,
    user: userData,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
