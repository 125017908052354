import React from "react";
import { Image } from "@chakra-ui/react";

export default function ImageComponent({ src, alt, height, radius }) {
  return (
    <Image
      boxSize="100%"
      objectFit="cover"
      h={height}
      src={src}
      alt={alt ? alt : "image"}
      borderRadius={radius ? radius : ""}
    />
  );
}
