import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import WhiteBG from "../ui/WhiteBG";
import Detail from "./Detail";
import DetailHeader from "../ui/DetailHeader";
import { useParams } from "react-router-dom";
import { viewReports } from "../../util/http";

export default function ReportDetailContainer({ setProgress }) {
  const token = localStorage.getItem("token");
  const params = useParams();

  const [report, setReport] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await viewReports(token, params.id);
        setProgress(80);
        setProgress(100);
        setReport(response.report);
      } catch (error) {
        setProgress(100);
      }
    }
    fetchData();
  }, [token, params.id]);
  return (
    <Box
      w="100%"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      mt="1.5rem"
    >
      <WhiteBG>
        <Box mb="2rem">
          <DetailHeader text="Departmental Assessment & Service Details Form" />
        </Box>
        <Detail report={report[0]} />
      </WhiteBG>
    </Box>
  );
}
