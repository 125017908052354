import React from "react";
import { Flex } from "@chakra-ui/react";
import Item from "./Item";

export default function List({ data, role }) {
  return (
    <Flex direction="column" height="75vh">
      {data?.map((item, i) => (
        <Item key={i} item={item} path={`${role}/${item.id}`} />
      ))}
    </Flex>
  );
}
