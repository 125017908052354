import React from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Button,
  Text,
  Spacer,
  Circle,
  MenuItem,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";

export default function ReportFooter({
  total,
  itemPerPage,
  setItemPerPage,
  setCurrentPage,
  currentPage,
  items,
  setItem,
}) {
  const lastPage = (total / 10).toFixed(0);

  let pages = [];

  for (let i = 1; i <= Math.ceil(total / itemPerPage); i++) {
    pages.push(i);
  }

  return (
    <Flex align="center">
      <Flex align="center" gap="1rem">
        <Menu>
          <MenuButton
            bg="white"
            border="1px solid #ccc"
            as={Button}
            rightIcon={<ChevronDownIcon />}
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
          >
            10
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                const reports = items.slice(0, 9);
                setItemPerPage(10);
                setItem(reports);
              }}
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
            >
              10
            </MenuItem>
            <MenuItem
              onClick={() => {
                const reports = items.slice(0, 19);
                setItemPerPage(20);
                setItem(reports);
              }}
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
            >
              20
            </MenuItem>
            <MenuItem
              onClick={() => {
                const reports = items.slice(0, 39);
                setItemPerPage(40);
                setItem(reports);
              }}
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
            >
              40
            </MenuItem>
            <MenuItem
              onClick={() => {
                const reports = items.slice(0, 59);
                setItemPerPage(60);
                setItem(reports);
              }}
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
            >
              60
            </MenuItem>
            <MenuItem
              onClick={() => {
                const reports = items.slice(0, 79);
                setItemPerPage(80);
                setItem(reports);
              }}
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
            >
              80
            </MenuItem>
            <MenuItem
              onClick={() => {
                const reports = items.slice(0, 99);
                setItemPerPage(100);
                setItem(reports);
              }}
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
            >
              100
            </MenuItem>
          </MenuList>
        </Menu>
        <Text
          display={{ base: "none", md: "none", lg: "block" }}
          color="#8A9099"
          fontSize="15px"
          fontWeight="medium"
        >
          showing {currentPage} - {itemPerPage * currentPage} of {total}
        </Text>
      </Flex>
      <Spacer />
      <Flex gap="1rem">
        <Circle
          onClick={() => setCurrentPage(1)}
          cursor="pointer"
          bg="#F8F8F8"
          p="0.4rem"
        >
          <ArrowLeftIcon color="#8A9099" boxSize={3} />
        </Circle>

        <Circle
          onClick={() =>
            setCurrentPage((prev) => (prev === 1 ? prev : prev - 1))
          }
          cursor="pointer"
          bg="#F8F8F8"
          p="0.4rem"
        >
          <ChevronLeftIcon color="#8A9099" boxSize={3} />
        </Circle>

        <Flex gap="1rem">
          {pages.map((page, i) => (
            <Button
              key={i}
              onClick={() => {
                setCurrentPage(page);
              }}
              size={{ base: "xs", md: "xs", lg: "sm" }}
              bg={page === currentPage ? "brand.900" : "white"}
              color={page === currentPage ? "white" : "brand.900"}
            >
              {page}
            </Button>
          ))}
        </Flex>

        <Circle
          onClick={() =>
            setCurrentPage((prev) => (prev === +lastPage ? prev : prev + 1))
          }
          cursor="pointer"
          bg="#F8F8F8"
          p="0.4rem"
        >
          <ChevronRightIcon color="#8A9099" boxSize={3} />
        </Circle>

        <Circle
          onClick={() => setCurrentPage(+lastPage)}
          cursor="pointer"
          bg="#F8F8F8"
          p="0.4rem"
        >
          <ArrowRightIcon color="#8A9099" boxSize={3} />
        </Circle>
      </Flex>
    </Flex>
  );
}
