import React, { useContext } from "react";
import ReusableSubHeader from "../ui/ReusableSubheader";
import { AuthContext } from "../../util/authContext";

export default function SubHeader() {
  const ctx = useContext(AuthContext);
  const user = ctx?.user;
  return (
    <ReusableSubHeader
      text={`Departmental Assessment & Service Details Form - ${user?.department} department`}
    />
  );
}
