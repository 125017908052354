import React, { useEffect } from "react";
import {
  Flex,
  Input,
  InputLeftAddon,
  InputGroup,
  Heading,
} from "@chakra-ui/react";

import InputComponent from "../../components/ui/Input";
import ReusableSelect from "../../components/ui/ReusableSelect";
import { useState } from "react";
import { getDepartment, getDirectorate } from "../../util/http";

export default function ReusableUserForm({
  setFirstName,
  setLastName,
  firstName,
  lastName,
  email,
  setEmail,
  phone,
  setPhone,
  setRole,
  setDirectorate,
  setDepartment,
  setDesignation,
  memberId,
  setMemberId,
  directorate,
  user,
  role,
}) {
  const token = localStorage.getItem("token");

  const [directorateList, setDirectorateList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [showDepartment, setShowDepartment] = useState(true);

  useEffect(() => {
    if (role.toLowerCase() === "directorate") {
      setShowDepartment(false);
    } else {
      setShowDepartment(true);
    }
  }, [role]);

  // console.log(user);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDirectorate(token);

        setDirectorateList(response?.directorates);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token]);

  const activeDirectorate =
    user?.user_role?.toLowerCase() === "directorate"
      ? user?.directorate
      : directorate;

  useEffect(() => {
    if (user?.user_role?.toLowerCase() === "directorate") {
      setDirectorate(user?.directorate);
    }
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDepartment(token, activeDirectorate);

        setDepartmentList(response?.directorates);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [
    token,
    directorate,
    user.directorate,
    user.user_role,
    setDirectorate,
    activeDirectorate,
  ]);

  return (
    <Flex direction="column" gap="2rem" align="center" justify="center">
      <Heading color="#555" as="h2" fontSize="20px" fontWeight="bold">
        Create a new user
      </Heading>
      <Flex w="100%" align="center" justify="space-between" gap="1rem">
        <InputComponent
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          label="First Name"
          placeholder="First Name"
        />
        <InputComponent
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          label="Last Name"
          placeholder="Last Name"
        />
      </Flex>
      <InputComponent
        value={memberId}
        onChange={(e) => setMemberId(e.target.value)}
        label="Member ID"
        placeholder="Member ID"
      />
      <InputComponent
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
        placeholder="Email"
        type="email"
      />

      <InputComponent
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        label="Phone Number"
        placeholder="Phone Number"
        type="number"
      />
      {false && (
        <InputGroup size="md">
          {false && (
            <InputLeftAddon
              borderLeftRadius="0.75rem"
              bg="transparent"
              children="+234"
            />
          )}
          <Input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            borderRightRadius="0.75rem"
            focusBorderColor="brand.900"
            placeholder="Phone number"
          />
        </InputGroup>
      )}

      {user?.user_role?.toLowerCase() !== "directorate" ? (
        <ReusableSelect
          onChange={(e) => setRole(e.target.value)}
          onChange1={(e) => setDirectorate(e.target.value)}
          label="User Role"
          label1="Directorate"
          options={
            user?.user_role?.toLowerCase() === "directorate"
              ? ["Member"]
              : ["Member", "Directorate"]
          }
          options1={directorateList || []}
        />
      ) : (
        <div className="input-container" style={{ width: "100%" }}>
          <label
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              textTransform: "capitalize",
              color: "#8A9099",
            }}
          >
            USER ROLE
          </label>
          <select
            style={{ textTransform: "capitalize" }}
            onChange={(e) => setRole(e.target.value)}
            className="input text-base capitalize"
          >
            <option style={{ textTransform: "capitalize" }} value="">
              please select
            </option>
            <option value="member">member</option>
          </select>
        </div>
      )}
      {showDepartment && (
        <ReusableSelect
          label="Department"
          label1="Designation"
          onChange={(e) => setDepartment(e.target.value)}
          onChange1={(e) => setDesignation(e.target.value)}
          options={departmentList || []}
          options1={["HOD", "Assistant HOD"]}
          disabled={activeDirectorate === ""}
        />
      )}
    </Flex>
  );
}
