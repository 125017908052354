import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

export default function ReusableComment({ comment, title, date, image }) {
  return (
    <Flex align="end" justify="end" w="100%">
      <Box w="90%" borderRadius="0.75rem" bg="#D7D7D7" p="1rem">
        <Text color="#3F434A" fontSize="14px">
          {comment}
        </Text>
        <Flex align="end" justify="end">
          <Flex align="center" gap="0.5rem">
            <Avatar name="candie" src={image} />
            <Flex direction="column" gap="0.2rem">
              <Text fontSize="12px" color="#3F434A">
                {title}
              </Text>
              <Text fontSize="12px" color="#3F434A">
                {date}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
