import React, { useEffect, useState } from "react";
import DashboardReusable from "../ui/DashboardReusable";
import ReusableSubHeader from "../ui/ReusableSubheader";
import ProfileContainer from "./ProfileContainer";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";
import { Fragment } from "react";
import Loader from "../ui/Loader";

export default function Profile() {
  const [user, setUser] = useState("");
  const [progress, setProgress] = useState(0);

  const ctx = useContext(AuthContext);

  useEffect(() => {
    setUser(ctx?.user);
  }, [ctx.user]);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable path="profile">
        <ReusableSubHeader text="Profile" />
        <ProfileContainer user={user} setProgress={setProgress} />
      </DashboardReusable>
    </Fragment>
  );
}
