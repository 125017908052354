import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import TotalDepartment from "./TotalDepartment";
import TotalDirectorates from "./TotalDirectorates";
import PendingReports from "./PendingReports";
import QueriedReports from "./QueriedReports";
import ApprovedReports from "./ApprovedReports";

export default function PastorDashboardReport() {
  return (
    <SimpleGrid
      mt="1.7rem"
      spacing={4}
      px="2.5rem"
      templateColumns="repeat(auto-fill, minmax(18%, 1fr))"
    >
      <TotalDirectorates />
      <TotalDepartment />
      <PendingReports />
      <QueriedReports />
      <ApprovedReports />
    </SimpleGrid>
  );
}
