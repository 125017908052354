import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function InputComponent({
  label,
  placeholder,
  type,
  register,
  value,
  onChange,
  isDisabled,
  min,
  max,
  required = false,
  defaultValue,
}) {
  return (
    <FormControl
      isRequired={required ? required : false}
      w={{ base: "100%", md: "100%", lg: "100%" }}
    >
      <FormLabel color="#8A9099" fontSize="14px" fontWeight="normal">
        {label}
      </FormLabel>
      <Input
      defaultValue = {defaultValue}
        size="md"
        placeholder={placeholder}
        type={type ? type : "text"}
        variant="outline"
        borderRadius="0.75rem"
        focusBorderColor="brand.900"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        isDisabled={isDisabled ? isDisabled : false}
        {...register}
      />
    </FormControl>
  );
}
