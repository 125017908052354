import React, { useContext, useEffect, useState } from "react";
import Form from "../ui/Form";
import ReusableInput from "../ui/ReusableInput";
import { Box, Heading, Flex, Button, useToast } from "@chakra-ui/react";
import DoubleInput from "../ui/DoubleInput";
import AddRowBtn from "../ui/AddRowBtn";
import ReusableTextarea from "../ui/ReusableTextarea";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createReport } from "../../util/http";
import InputComponent from "../ui/Input";
import { useNavigate } from "react-router-dom";
import { validatePhoneLength } from "../../util/validator";
import { AuthContext } from "../../util/authContext";
import ReusableMixedField from "../ui/ReusableMixedField";
import ReusableCloseBtn from "../ui/ReusableCloseBtn";

export default function ReportForm({
  progress,
  setProgress,
  onOpen,
  setMessage,
}) {
  const reportSchema = yup.object().shape({
    report_date: yup.string(),
    service: yup.string(),
    department_members: yup.string(),
    members_in_service: yup.string(),
    members_served: yup.string(),
    those_sick: yup.string(),
    reason_sick_and_travelled: yup.string(),
    name_absentee: yup.string(),
    reason_absentee: yup.string(),
    excellence: yup.string(),
    need_improvement: yup.string(),
    observation: yup.string(),
    absentees: yup.string(),
    // hod_remark: yup.string().default("not an HOD"),
    // assistant_remark: yup.string().default("not an assistant"),
  });

  const todayDate = new Date(Date.now());

  const day = todayDate.getDate();
  const month = todayDate.getMonth() + 1;
  const year = todayDate.getFullYear();

  const toast = useToast();

  const ctx = useContext(AuthContext);

  const user = ctx.user;

  const [obtainedPermission, setObtainerPermission] = useState("");

  const [notObtainedPermission, setNotObtainerPermission] = useState("");

  const [thoseSick, setThoseSick] = useState("");

  const [reasons, setReasons] = useState("");
  const [hod, setHod] = useState("");
  const [asstHod, setAsshod] = useState("");

  const {
    register,
    handleSubmit,
    watch ,
    formState: { errors  ,   },
  } = useForm({
    resolver: yupResolver(reportSchema),
  });


  const token = localStorage.getItem("token");

  const [permission, setPermission] = useState([1]);
  const [permissionPhone, setPermissionPhone] = useState([]);
  //fix
  const [permissionRequired ,  setPermissionRequired] = useState(false)

  const [noPermission, setNoPermission] = useState([1]);
  const [noPermissionPhone, setNoPermissionPhone] = useState([]);

  //fix
  const [noPermissionRequired ,  setNoPermissionRequired] = useState(false)

  const [sick, setSick] = useState([1]);
  const [sickPhone, setSickPhone] = useState([]);

  //fix
  const [sickRequired ,  setSickRequired] = useState(false)

  const [reason, setReason] = useState([1]);
  const [reasonPhone, setReasonPhone] = useState([]);

  //fix
  const [reasonRequired ,  setReasonRequired] = useState(false)

  useEffect(()=>{

    if((reason?.join('')?.length > 0 && reason?.join('') !== "1") || reasonPhone?.join('')?.length > 0
    ){
       setReasonRequired(true)
    }
    else{
      setReasonRequired(false)
    }
    //

      if((permission?.join('')?.length > 0 && permission?.join('') !== "1") || permissionPhone?.join('')?.length > 0
    ){
       setPermissionRequired(true)
    }
    else{
      setPermissionRequired(false)
    }


    if((noPermission?.join('')?.length > 0 && noPermission?.join('') !== "1")
    || noPermissionPhone?.join('')?.length > 0
    ){
       setNoPermissionRequired(true)
    }
    else{
      setNoPermissionRequired(false)
    }



    if((sick?.join('')?.length > 0 && sick?.join('') !== "1") ||  sickPhone?.join('')?.length > 0
    ){
       setSickRequired(true)
    }
    else{
      setSickRequired(false)
    }



  } , [noPermission, noPermissionPhone, permission, permissionPhone, reason, reasonPhone, sick, sickPhone])

console.log(reasonRequired ,  noPermissionRequired , permissionRequired , sickRequired)


  const [numMemberService, setNumMemberService] = useState("");
  const [numMemberDepartment, setNumMemberDepartment] = useState("");

  const [numAbsentee, setNumAbsentee] = useState(0);
  const [numMemberServed, setNumMemberServed] = useState("");

  useEffect(() => {
    const absentNumber = +numMemberDepartment - +numMemberService;
    setNumAbsentee(absentNumber);
  }, [numMemberDepartment, numMemberService]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /////////////
  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  }

  ///////////////////////////
  function addPermissionField(e, i) {
    const inpData = [...permission];
    inpData[i] = e.target.value;

    setPermission(inpData);
  }

  function addPermissionPhone(e, i) {
    const inpData = [...permissionPhone];
    inpData[i] = e.target.value;
    setPermissionPhone(inpData);

  }

  function handleAdd() {
    const inp = [...permission, []];
    setPermission(inp);
  }

  function addInput() {
    const newArr = permission.map((el, i) => {
      return {
        full_name: el,
        phone_number: permissionPhone[i],
      };
    });

    const jsonArr = JSON.stringify(newArr);

    setObtainerPermission(jsonArr);
  }

  //////////////////////
  // not obtained permission
  function addNoPermissionField(e, i) {
    const inpData = [...noPermission];
    inpData[i] = e.target.value;
    setNoPermission(inpData);
  }

  function addNoPermissionPhone(e, i) {
    const inpData = [...noPermissionPhone];
    inpData[i] = e.target.value;
    setNoPermissionPhone(inpData);
  }

  function handleAdd1() {
    const inp = [...noPermission, []];
    setNoPermission(inp);
  }

  function addInput1() {
    const newArr = noPermission.map((el, i) => {
      return {
        full_name: el,
        phone_number: noPermissionPhone[i],
      };
    });

    const jsonArr = JSON.stringify(newArr);
    setNotObtainerPermission(jsonArr);
  }

  ////////////////////////
  // those sick and travelled
  function addSickField(e, i) {
    const inpData = [...sick];
    inpData[i] = e.target.value;
    setSick(inpData);
  }

  function addSickReason(e, i) {
    const inpData = [...sickPhone];
    inpData[i] = e.target.value;
    setSickPhone(inpData);
  }

  function handleAdd2() {
    const inp = [...sick, []];
    setSick(inp);
  }

  function addInput2() {
    const newArr = sick.map((el, i) => {
      return {
        full_name: el,
        phone_number: sickPhone[i],
      };
    });

    const jsonArr = JSON.stringify(newArr);

    setThoseSick(jsonArr);
  }

  ////////////////////////
  // reason for being absent
  function addReasonField(e, i) {

    const inpData = [...reason];
    inpData[i] = e.target.value;
    setReason(inpData);
  }

  function addReasonPhone(e, i) {
    const inpData = [...reasonPhone];
    inpData[i] = e.target.value;
    setReasonPhone(inpData);
  }

  function handleAdd3() {
    const inp = [...reason, []];
    setReason(inp);
  }

  function addInput3() {
    const newArr = reason.map((el, i) => {
      return {
        full_name: el,
        reason: reasonPhone[i],
      };
    });

    const jsonArr = JSON.stringify(newArr);

    setReasons(jsonArr);
  }

  async function reportHandler(data) {
    setLoading(true);

    const newData = {
      ...data,
      hod_remark: hod || "",
      assistant_remark: asstHod || "",
      obtained_permission: obtainedPermission || "",
      not_obtain_permission: notObtainedPermission || "",
      reasons: reasons || "",
      department_members: numMemberDepartment,
      members_in_service: numMemberService,
      members_served: numMemberServed,
      absentees: +numAbsentee,
      those_sick: thoseSick || "",
      designation: user?.designation,
      department: user?.department,
      directorate: user?.directorate,
    };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await createReport(newData, token);

      navigate("/report");
      setLoading(false);
      showToast("Report successfully created", "", "success");
      setProgress(80);
      setProgress(100);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setProgress(100);
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
    }
  }

  return (
    <Box
      w="100%"
      mt="1.5rem"
      bg="transparent"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
    >
      <Flex
        w="100%"
        direction="column"
        gap="2.5rem"
        p={{ base: "1rem", md: "1rem", lg: "2.5rem" }}
        borderRadius="0.75rem"
        bg="white"
      >
        <Box w="100%">
          <Heading
            as="h2"
            fontSize={{ base: "10px", md: "10px", lg: "18px" }}
            fontWeight="medium"
            color="#3F434A"
          >
            Departmental Assessment & Service Details Form{" "}
          </Heading>
        </Box>
        <Form onSubmit={handleSubmit(reportHandler)}>
          <Flex
            w="100%"
            align="center"
            direction={{ base: "column", md: "column", lg: "row" }}
            gap="2rem"
            justify="space-between"
          >
            <Box w={{ lg: "50%", base: "100%", md: "100%" }}>
              <InputComponent
                required={true}
                register={{ ...register("report_date") }}
                type="date"
                label="Date"
                min={`${year}-${month.toString().padStart(2, 0)}-${day
                  .toString()
                  .padStart(2, 0)}`}
                max=""
              />
            </Box>
            <div className="input-container ">
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#515262",
                }}
              >
                Service
              </label>
              <select
                required
                {...register("service", {
                  required: {
                    value: true, message: 'Please select field'
                  }
                })}
                className="input text-base capitalize "
              >
                <option value="">please select</option>
                <option value="All services">All services</option>
                <option value="First service">First service</option>
                <option value="Second service">Second service</option>
                {/* <option value="Third service">Third service</option> */}
              </select>
            </div>
          </Flex>

          <DoubleInput
            required={true}
            placeholder="e.g 3"
            type="number"
            label1="No. of Department Members"
            max={11}
            onChange1={(e) => {
              const val = e.target.value;

              setNumMemberDepartment(val);
            }}
            label2="No. of Members in Service"
            onChange2={(e) => {
              if (+e.target.value > +numMemberDepartment) {
                e.target.value = "";
                showToast(
                  "Number of member in service cannot be greater than members in department",
                  "",
                  "warning"
                );
                return;
              } else {
                setNumMemberService(e.target.value);
              }
            }}
          />
          <DoubleInput
            required={true}
            placeholder="e.g 3"
            label1="No. OF MEMBERS WHO SERVED"
            label2="TOTAL NO. OF ABSENTEES"
            onChange1={(e) => setNumMemberServed(e.target.value)}
            onChange2={(e) => setNumAbsentee(e.target.value)}
            value2={numAbsentee}
            readOnly={true}
          />

          <ReusableInput
            label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
            array={permission}
            setArray={setPermission}
            placeholder="Fullname"
            placeholder2="Phone Number"
            onChange1={addPermissionField}
            onChange2={addPermissionPhone}
            onBlur={addInput}
            type="number"
            required={permissionRequired}
          />
          <Flex align="center" justify="end" gap="1rem">
            <AddRowBtn onClick={handleAdd} />
          </Flex>

          <ReusableInput
            label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
            array={noPermission}
            setArray={setNoPermission}
            placeholder="Fullname"
            placeholder2="Phone Number"
            onChange1={addNoPermissionField}
            onChange2={addNoPermissionPhone}
            onBlur={addInput1}
            type="number"
            required={noPermissionRequired}
          />

          <Flex align="center" justify="end" gap="1rem">
            <AddRowBtn onClick={handleAdd1} />
          </Flex>

          <ReusableInput
            label1="Those Sick, Travelled etc"
            array={sick}
            setArray={setSick}
            placeholder="Fullname"
            type="number"
            placeholder2="Phone Number"
            onChange1={addSickField}
            onChange2={addSickReason}
            onBlur={addInput2}
            required={sickRequired}
          />
          <Flex align="center" justify="end" gap="1rem">
            <AddRowBtn onClick={handleAdd2} />
          </Flex>

          <ReusableMixedField
            label1="Reason for being Absent"
            array={reason}
            setArray={setReason}
            placeholder="Title"
            placeholder2="Reason"
            onChange1={addReasonField}
            onChange2={addReasonPhone}
            onBlur={addInput3}
            required= {reasonRequired}
          />

          <Flex align="center" justify="end" gap="1rem">
            <AddRowBtn onClick={handleAdd3} />
          </Flex>
          <ReusableTextarea
            required={true}
            readOnly={true}
            register={{ ...register("excellence") }}
            label="Points of Excellence (POES)"
          />
          <ReusableTextarea
            required={true}
            readOnly={true}
            register={{ ...register("need_improvement") }}
            label="Needs for Improvement (NFIs)"
          />
          <ReusableTextarea
            // required={true}
            readOnly={true}
            register={{ ...register("observation") }}
            label="any other concern or observation"
          />
          <Box w="100%" py="1rem">
            <Heading
              as="h2"
              color="#3f434a"
              fontSize="18px"
              fontWeight="medium"
            >
              SIGN OFF REMARK
            </Heading>
          </Box>

          {user?.designation?.toLowerCase() === "hod" ? (
            <ReusableTextarea
              required={true}
              readOnly={true}
              onChange={(e) => setHod(e.target.value)}
              value={hod}
              label="hod’s remark"
            />
          ) : (
            <ReusableTextarea
              required={true}
              readOnly={true}
              onChange={(e) => setAsshod(e.target.value)}
              value={asstHod}
              label="ASSISTant HOD’s REMark"
            />
          )}
          <Flex w="100%" align="end" justify="end">
            <Button
              variant="solid"
              type="submit"
              borderRadius="14px"
              color="white"
              bg="#481053"
              fontSize="15px"
              fontWeight="medium"
              px="3rem"
              py="0.5rem"
              _hover={{ opacity: "80%" }}
              isLoading={loading}
              loadingText="Submitting"
            >
              Submit
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Box>
  );
}
