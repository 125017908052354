import React from "react";

export default function PasswordValidator({ error, warning, success }) {
  return (
    <div className="flex w-full items-center justify-center h-[5px]">
      <div
        className={`w-1/3 flex items-center h-full ${
          error ? "bg-[#FF3436]" : "bg-white"
        }`}
      ></div>

      <div
        className={`w-1/3 flex items-center h-full ${
          warning ? "bg-[#F4ED48]" : "bg-white"
        } `}
      ></div>

      <div
        className={`w-1/3  flex items-center h-full ${
          success ? "bg-[#00AC45]" : "bg-white"
        }`}
      ></div>
    </div>
  );
}
