import { NavLink } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import ImageComponent from "./Image";

export default function NavItem({ src, alt, height, path, title }) {
  return (
    <NavLink to={`/${path}`} className="menu">
      <Box w="16px">
        <ImageComponent src={src} alt={alt} height={height} />
      </Box>
      {title}
    </NavLink>
  );
}
