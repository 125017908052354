import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import ButtonComponent from "../../components/ui/Button";

export default function ReusableUserHead({ open }) {
  return (
    <Box w="100%" px="2.5rem" className="w-full px-10">
      <Heading as="h2" fontSize="18px" fontWeight="medium" pt="1.5rem" pb="0px">
        <ButtonComponent title="Create User" onClick={open} />
      </Heading>
    </Box>
  );
}
