import React, { useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import TotalDepartment from "./TotalDepartment";
import PendingReports from "./PendingReports";
import QueriedReports from "./QueriedReports";
import ApprovedReports from "./ApprovedReports";
import { getUsers } from "../../util/http";
import TotalDirectorate from "./TotalDirectorate";

export default function DashboardReport({ report }) {
  const id = localStorage.getItem("memberID");
  const token = localStorage.getItem("token");
  const [user, setUser] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUsers(token, id);
        setUser(response?.user[0]);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token, id]);

  return (
    <SimpleGrid
      spacing={4}
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      templateColumns={
        user?.user_role?.toLowerCase() === "directorate"
          ? {
              base: "repeat(auto-fill, minmax(45%, 1fr))",
              md: "repeat(auto-fill, minmax(45%, 1fr))",
              lg: "repeat(auto-fill, minmax(22%, 1fr))",
            }
          : {
              base: "repeat(auto-fill, minmax(45%, 1fr))",
              md: "repeat(auto-fill, minmax(45%, 1fr))",
              lg: "repeat(auto-fill, minmax(18%, 1fr))",
            }
      }
    >
      {user?.user_role?.toLowerCase() === "pastor" && (
        <TotalDirectorate report={report.total_directorates} />
      )}
      <TotalDepartment report={report.total_departments} />
      <PendingReports report={report.pending_reports} />
      <QueriedReports report={report.queried_reports} />
      <ApprovedReports report={report.approved_reports} />
    </SimpleGrid>
  );
}
