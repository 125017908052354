import React, { useState, useRef, Fragment, useEffect } from "react";

import CreateUserForm from "./CreateUserForm";

import ReusableCreateUser from "../ui/ReusableCreateUser";
import { useToast } from "@chakra-ui/react";
import { createUser, getUsers } from "../../util/http";

export default function CreateUser({
  isOpen,
  onOpen,
  onClose,
  progress,
  setProgress,
}) {
  const btnRef = React.useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [role, setRole] = useState("");
  const [directorate, setDirectorate] = useState("");
  const [image, setImage] = useState("");
  const [memberId, setMemberId] = useState("");

  const [loading, setLoading] = useState();

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");
  const [user, setUser] = useState("");

  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUsers(token, id);
        setUser(response?.user[0]);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token, id]);

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 5000,
      isClosable: true,
    });
  }

  async function createUserHandler() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phone,
      department,
      designation,

      directorate,
      user_role: role,
      member_id: memberId,
    };

    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await createUser(data, token);
      onClose();
      showToast("Successful", "User successfully created", "success");
      setProgress(80);
      setProgress(100);
    } catch (error) {
      // console.log(error);
      showToast(
        "ERROR",
        error?.response?.data.message || "Something went wrong",
        "error"
      );
      setLoading(false);
      setProgress(100);
    }
  }

  return (
    <ReusableCreateUser
      btnRef={btnRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClick={createUserHandler}
    >
      <CreateUserForm
        user={user}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        setImage={setImage}
        setDepartment={setDepartment}
        setDesignation={setDesignation}
        progress={progress}
        setProgress={setProgress}
        role={role}
        setRole={setRole}
        setDirectorate={setDirectorate}
        memberId={memberId}
        setMemberId={setMemberId}
        directorate={directorate}
      />
    </ReusableCreateUser>
  );
}
