import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputLeftAddon,
  InputGroup,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Form from "../../components/ui/Form";
import InputComponent from "../../components/ui/Input";
import { useContext, useState } from "react";
import { editUserAdmin, getDepartment, getDirectorate } from "../../util/http";
import ReusableSelect from "../../components/ui/ReusableSelect";
import { AuthContext } from "../../util/authContext";
import { useEffect } from "react";

export default function EditUser({ isOpen, onClose, user }) {
  const [firstName, setFirstName] = useState(user.first_name || "");
  // const [firstName, setFirstName] = useState("firstname");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [phone, setPhone] = useState(user.phone_number || "");
  const [department, setDepartment] = useState(user.department || "");
  const [designation, setDesignation] = useState(user.designation || "");
  const [directorate, setDirectorate] = useState(user.directorate || "");
  const [role, setRole] = useState(user?.user_role || "");
  const [memberId, setMemberId] = useState(user.member_id || "");

  function capitalizeEachWord(sentence) {
    if(sentence === null || sentence === undefined) {
      return sentence;

    }
    return sentence.replace(/\b\w/g, c => c.toUpperCase());
}


  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setPhone(user.phone_number);
    setDepartment(user.department);
    setDesignation(user.designation);
    setDirectorate(user.directorate);
    setRole(user.user_role);
    setMemberId(user.member_id);

  }, [user])




  const [directorateList, setDirectorateList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const ctx = useContext(AuthContext);

  const token = localStorage.getItem("token");

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  }
  const curUser = ctx.user;

  async function editHandler() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: user?.email,
      phone: phone,
      department,
      designation,
    };

    try {
      setLoading(true);
      const response = await editUserAdmin(token, data, user.id);
      showToast("Successful", "Profile successfully updated", "success");
      onClose();
      window.location.reload();
      setLoading(false);
    } catch (error) {
      // console.log(error);
      showToast(
        "ERROR",
        error?.response?.data.message || "Something went wrong",
        "error"
      );
      setLoading(false);
    }
  }

  const activeDirectorate =
    curUser?.user_role?.toLowerCase() === "directorate"
      ? curUser?.directorate
      : directorate;

  useEffect(() => {
    if (user?.user_role?.toLowerCase() === "directorate") {
      setDirectorate(user?.directorate);
    }
  }, [user?.directorate, user?.user_role]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDepartment(token, activeDirectorate);

        setDepartmentList(response?.directorates);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [
    token,
    directorate,
    user.directorate,
    user.user_role,
    setDirectorate,
    activeDirectorate,
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDirectorate(token);

        setDirectorateList(response?.directorates);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token]);



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form>
            <Flex align="center" justify="space-between" gap="1rem">
              <InputComponent
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
                placeholder="First Name"
              />
              <InputComponent
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
                placeholder="Last Name"
              />
            </Flex>
            <Flex align="center" justify="space-between" gap="1rem">
              <InputComponent
                value={email}
                label="Email"
                placeholder="Email"
                type="email"
                isDisabled={true}
              />
              <InputComponent
                value={memberId}
                label="Member ID"
                placeholder="Member ID"
                type="text"
                onChange={(e) => setMemberId(e.target.value)}
              />
            </Flex>
            <InputGroup size="md">
              <InputLeftAddon
                borderLeftRadius="0.75rem"
                bg="transparent"
                children="+234"
              />
              <Input
                type="tel"
                borderRightRadius="0.75rem"
                focusBorderColor="brand.900"
                placeholder="phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </InputGroup>
            {false && (
              <Flex align="center" gap="1rem">
                <InputComponent
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  label="Department"
                  placeholder="Department"
                />
                <InputComponent
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  label="Designation"
                  placeholder="Designation"
                />
              </Flex>
            )}
            <ReusableSelect
              defaultValue1={designation}
              defaultValue2={directorate}
              label="Designation"
              label1="Directorate"
              onChange={(e) => setDesignation(e.target.value)}
              onChange1={(e) => setDirectorate(e.target.value)}
              options={["HOD", "Assistant HOD"]}
              options1={
                curUser?.user_role.toLowerCase() === "directorate"
                  ? [curUser?.directorate]
                  : directorateList
              }
            />
            <ReusableSelect
              defaultValue1={role}
              defaultValue2={department}
              label="User Role"
              label1="Department"
              onChange1={(e) => setDepartment(e.target.value)}
              onChange={(e) => setRole(e.target.value)}
              options={
                curUser?.user_role?.toLowerCase() === "directorate"
                  ? ["member"]
                  : ["member", "directorate"]
              }
              options1={departmentList}
            />
          </Form>
        </ModalBody>

        <ModalFooter
          display="flex"
          align="center"
          justifyContent="space-between"
        >
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={loading}
            loadingText="Saving"
            bg="brand.900"
            color="white"
            variant="solid"
            onClick={editHandler}
          >
            Save Details
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
