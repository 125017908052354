import ReusableUserForm from "../ui/ReusableUserForm";

export default function CreateUserForm({
  firstName,
  lastName,
  setFirstName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  setDepartment,
  setDesignation,
  setImage,
  setRole,
  role,
  setDirectorate,
  directorate,
  memberId,
  setMemberId,
  user,
}) {
  return (
    <ReusableUserForm
      firstName={firstName}
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      setImage={setImage}
      setDepartment={setDepartment}
      setDesignation={setDesignation}
      setRole={setRole}
      setDirectorate={setDirectorate}
      directorate={directorate}
      memberId={memberId}
      setMemberId={setMemberId}
      user={user}
      role={role}
    />
  );
}
