import React from "react";
import { Button } from "@chakra-ui/react";

export default function ButtonComponent({
  title,
  onClick,
  type,
  disabled,
  isLoading,
  loadText,
  bg,
  color,
}) {
  return (
    <Button
      onClick={onClick}
      variant="solid"
      borderRadius="14px"
      px="2rem"
      bg={bg ? bg : "brand.900"}
      color={color ? color : "white"}
      type={type ? type : "button"}
      isDisabled={disabled ? disabled : false}
      isLoading={isLoading ? isLoading : false}
      loadingText={loadText ? loadText : ""}
    >
      {title}
    </Button>
  );
}
