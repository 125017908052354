import React from "react";
import { Box } from "@chakra-ui/react";

export default function WhiteBG({ children }) {
  return (
    <Box bg="white" borderRadius="0.75rem" h="100%" py="1.5rem" px="2rem">
      {children}
    </Box>
  );
}
