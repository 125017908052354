import React from "react";
import { Box, InputGroup, Flex, Input, InputLeftAddon } from "@chakra-ui/react";
import ButtonComponent from "../../components/ui/Button";
import { SearchIcon } from "@chakra-ui/icons";

export default function ReusableUserFilter({ onChange }) {
  return (
    <Flex align="center" justify="space-between">
      <InputGroup
        w="100%"
        size="md"
        border="1px solid #ccc"
        borderRadius="0.75rem"
      >
        <InputLeftAddon
          borderLeftRadius="0.75rem"
          bg="white"
          border="none"
          children={<SearchIcon />}
        />
        <Input
          variant="unstyled"
          borderRightRadius="0.75rem"
          border="none"
          type="search"
          placeholder="Search report..."
          onChange={onChange}
        />
      </InputGroup>
    </Flex>
  );
}
