import React, { Fragment, useEffect, useState } from "react";

import DashboardCenter from "./DashboardCenter";
import { getDashboard, getPastorDashboard, getUsers } from "../../util/http";
import DashboardReusable from "../ui/DashboardReusable";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";
import Loader from "../ui/Loader";
import { sortData } from "../../util/lib";

export default function Dashboard() {
  const [report, setReport] = useState("");
  const [user, setUser] = useState("");
  const [allReports, setAllReports] = useState([]);
  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState(false);
  const ctx = useContext(AuthContext);

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getDashboard(token);
        const result = sortData(response?.reports);
        setProgress(80);
        setProgress(100);
        setLoading(false);

        setUser(ctx?.user);
        setAllReports(result.reverse());
        setReport(response);
      } catch (error) {
        setProgress(100);
      }
    }
    fetchData();
  }, [ctx.user, token]);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable path="profile">
        <DashboardCenter
          allReports={allReports}
          setAllReports={setAllReports}
          setReport={setReport}
          user={user}
          report={report}
        />
      </DashboardReusable>
    </Fragment>
  );
}
