import React, { Fragment, useEffect, useState } from "react";
import AuthComponent from "../ui/AuthComponent";
import {
  HStack,
  Heading,
  PinInput,
  PinInputField,
  Text,
  Button,
  ButtonGroup,
  Container,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Loader from "../ui/Loader";

export default function ResetPassword() {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");

  const [progress, setProgress] = useState(0);

  const [validInput, setValidInput] = useState(true);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6) {
      // console.log(true);
      setError(true);
    } else {
      setError(false);
    }
  }, [input1, input2, input3, input4, input5, input6]);

  async function resetHandler() {
    setLoading(true);
    const token = `${input1}${input2}${input3}${input4}${input5}${input6}`;
    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      setProgress(80);
      const response = "";
      setProgress(100);
      setLoading(false);
      navigate("/new-password");
    } catch (error) {
      setLoading(false);
      setProgress(100);

      // console.log(error);
    }
  }

  async function resendHandler() {
    setLoading(true);

    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);

      setProgress(80);
      setTimeout(() => {
        setProgress(100);
        setLoading(false);
        navigate("/new-password");
      }, 3000);
    } catch (error) {
      setLoading(false);
      setProgress(100);

      // console.log(error);
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent
        heading="Reset Password"
        text="Remember your login details?"
        path="login"
        linkText="Sign In"
        blue={true}
      >
        <Heading
          color="brand.900"
          fontSize="14px"
          fontWeight="normal"
          textAlign="center"
          as="h2"
          mb="3rem"
        >
          Please enter the OTP sent to you email to continue
        </Heading>
        <HStack spacing="2rem" display="flex" align="center" justify="center">
          <PinInput
            autoFocus
            isInvalid={!validInput}
            size="lg"
            placeholder=""
            focusBorderColor="#481053"
            otp
          >
            <PinInputField onChange={(e) => setInput1(e.target.value)} />
            <PinInputField onChange={(e) => setInput2(e.target.value)} />
            <PinInputField onChange={(e) => setInput3(e.target.value)} />
            <PinInputField onChange={(e) => setInput4(e.target.value)} />
            <PinInputField onChange={(e) => setInput5(e.target.value)} />
            <PinInputField onChange={(e) => setInput6(e.target.value)} />
          </PinInput>
        </HStack>
        {!validInput && (
          <Text mt="2rem" textAlign="center" color="error.900">
            Invalid token inputed, please try again or request another token
          </Text>
        )}
        <Text mt="1rem" textAlign="center" color="gray.900">
          The verification code will be expire in 01:23
        </Text>
        <Container mt="1rem">
          <ButtonGroup
            display="flex"
            align="center"
            justify="center"
            w="100%"
            gap="4"
          >
            <Button
              onClick={resendHandler}
              variant="ghost"
              color="brand.900"
              fontWeight="bold"
            >
              Resend code
            </Button>
            <Button
              onClick={resetHandler}
              isLoading={loading}
              loadingText="Processing"
              flex="1"
              color="white"
              bg="brand.900"
            >
              Submit
            </Button>
          </ButtonGroup>
        </Container>
      </AuthComponent>
    </Fragment>
  );
}
