import React, { Fragment, useState } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import PastorUserHead from "./PastorUserHead";
import PastorUserContainer from "./PastorUserContainer";
import { useDisclosure } from "@chakra-ui/react";
import PastorCreateUser from "./PastorCreateUser";

export default function PastorUsers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [progress, setProgress] = useState(0);
  return (
    <Fragment>
      <DashboardReusable
        dashboard="pastor-dashboard"
        report="pastor-report"
        user="pastor-user"
        profile="pastor-profile"
        show={true}
      >
        <PastorUserHead open={onOpen} />
        <PastorUserContainer />
      </DashboardReusable>
      <PastorCreateUser
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        progress={progress}
        setProgress={setProgress}
      />
    </Fragment>
  );
}
