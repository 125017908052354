import React from "react";
import { Box } from "@chakra-ui/react";
import WhiteBG from "../../components/ui/WhiteBG";
import PastorUserFilter from "./PastorUserFilter";
import PastorUserTitle from "./PastorUserTitle";
import PastorUserList from "./PastorUserList";
import ReportFooter from "../../components/report/ReportFooter";

export default function PastorUserContainer() {
  return (
    <Box w="100%" px="10" mt="1.5rem">
      <WhiteBG>
        <PastorUserFilter />
        <Box mt="1.5rem">
          <PastorUserTitle />
        </Box>
        <Box mt="1.5rem">
          <PastorUserList />
          <ReportFooter />
        </Box>
      </WhiteBG>
    </Box>
  );
}
