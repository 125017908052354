import React, { Fragment, useEffect, useState } from "react";
import DashboardSide from "../dashboard/DashboardSide";
import Header from "../dashboard/Header";

import { Flex } from "@chakra-ui/react";
import { getUsers } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";

export default function DashboardReusable({
  children,
  title,
  dashboard,
  report,
  user,
  profile,
  show,
  path,
}) {
  const ctx = useContext(AuthContext);

  const curUser = ctx.user;

  return (
    <Flex w="100%" alignItems="center">
      <DashboardSide
        dashboard={dashboard}
        user={user}
        report={report}
        profile={profile}
        show={show}
      />
      <Fragment>
        <div className="flex-1 bg-[#F6F6F6] h-full lg:h-[130vh]">
          <Header
            dashboard={dashboard}
            user={user}
            report={report}
            profile={profile}
            show={show}
            path={path}
            curUser={curUser}
            title={title}
          />
          {children}
        </div>
      </Fragment>
    </Flex>
  );
}
