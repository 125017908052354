import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [navigate]);
  return (
    <div className="w-full h-[100vh] bg-white flex items-center justify-center">
      Page Not Found
    </div>
  );
}
