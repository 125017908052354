import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import SubmissionTitle from "./SubmissionTitle";
import SubmissionList from "./SubmissionList";
import { Link, useNavigate } from "react-router-dom";
import DirectorateSubmissionHeader from "./DirectorateSubmissionHeader";

export default function DashboardSubmission({ reports, setAllReports }) {
  const navigate = useNavigate();
  return (
    <Box
      w="100%"
      mt="1.5rem"
      bg="transparent"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
    >
      <Flex
        w="100%"
        maxHeight="93vh"
        direction="column"
        gap="1.5rem"
        // height="100%"
        bg="white"
        borderRadius="0.75rem"
        p="1rem"
      >
        <DirectorateSubmissionHeader setReport={setAllReports} />
        <SubmissionTitle />
        <SubmissionList reports={reports} />
        <Flex align="end" justify="end">
          <Button
            onClick={() => navigate("/directorate-report")}
            bg="#f8f8f8"
            color="brand.900"
            fontWeight="medium"
          >
            View All
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
