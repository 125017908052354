import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import Icon from "../ui/Icon";
import {
  Badge,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
} from "@chakra-ui/react";
import SubmissionText from "../ui/SubmissionText";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function ReusableItem({ item, user, path }) {
  const navigate = useNavigate();

  const reportTime = new Date(item?.created_at);

  const day = reportTime.getDate();
  const month = reportTime.getMonth() + 1;
  const year = reportTime.getFullYear();

  const hour = reportTime.getHours();
  const minute = reportTime.getMinutes();

  return (
    <Flex
      cursor="pointer"
      _hover={{ opacity: "80%", transition: "all", transitionDuration: "300" }}
      py="2"
      align="center"
      justify="space-between"
      onClick={() => navigate(`/report/${item.id}`)}
    >
      <SubmissionText text={item.service} width="20%" />

      <SubmissionText
        display={{ base: "none", md: "none", lg: "block" }}
        width="20%"
        text={user?.department}
        fontWeight="normal"
        color="#8A9099"
      />
      <SubmissionText
        text={`${day.toString().padStart(2, 0)}/${month
          .toString()
          .padStart(2, 0)}/${year} ${hour.toString().padStart(2, 0)}:${minute
          .toString()
          .padStart(2, 0)}`}
        width="20%"
      />

      <Flex w="20%" justify="center">
        <Text
          bg={
            item.status === "pending"
              ? "rgba(178, 179, 179, 0.1)"
              : item.status === "queried"
              ? "rgba(255, 0, 0, 0.1)"
              : item.status === "replied"
              ? "rgb(234, 221, 202, 0.4)"
              : "rgba(73, 201, 109, 0.1)"
          }
          fontWeight="medium"
          fontSize={{ base: "10px", md: "10px", lg: "16px" }}
          textAlign="center"
          color={
            item.status === "pending"
              ? "#828282"
              : item.status === "queried"
              ? "error.900"
              : item.status === "replied"
              ? "yellow.700"
              : "success.900"
          }
          py="8px"
          px={{ base: "0.5rem", md: "0.5rem", lg: "rem" }}
          borderRadius="0.75rem"
          style={{ textTransform: "capitalize" }}
        >
          {item.status}
        </Text>
      </Flex>

      <Flex align="center" justify="end" cursor="pointer" w="20%">
        <Box w="50%">
          <SubmissionText text={item.designation} fw="normal" />
        </Box>
        <Menu>
          <MenuButton
            _hover={{ bg: "#fff", text: "brand.900" }}
            _expanded={{ bg: "transparent" }}
            bg="transparent"
            colorScheme="purple"
            as={Button}
          >
            <Icon icon={<BsThreeDotsVertical />} />
          </MenuButton>
          <MenuList>
            <MenuItem
              display="flex"
              justify="center"
              align="center"
              cursor="pointer"
              gap="1rem"
              _focus={{ bg: "#fff" }}
              _hover={{ bg: "#fff" }}
              _expanded={{ bg: "#fff" }}
              onClick={() => navigate(`/report/${item.id}`)}
            >
              <Icon icon={<AiOutlineEye />} />
              <SubmissionText text="View Report" fw="normal" />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
