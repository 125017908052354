import React, { Fragment } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";

export default function ReusableMixedField({
  label1,
  label2,
  placeholder,
  placeholder2,
  value1,
  onChange1,
  value2,
  onChange2,
  array,
  setArray,
  type,
  onBlur,
  required
}) {
  function handleDelete(i) {
    const deleteVal = [...array];
    deleteVal.splice(i);
    setArray(deleteVal);
  }
  return (
    <Fragment>
      {array.map((item, i) => (
        <Flex
          w="100%"
          key={i}
          align="center"
          direction={{ base: "column", md: "column", lg: "row" }}
          justify="space-between"
          gap="2rem"
          position="relative"
        >
          <FormControl
            w={{ base: "100%", md: "100%", lg: "48%" }}
            height={{ base: "100%", md: "100%", lg: "100px" }}
          >
            <FormLabel
              fontSize="14px"
              fontWeight="normal"
              h={{ lg: "20px", base: "100%", md: "100%" }}
              color="#515262"
              style={{ textTransform: "uppercase" }}
            >
              {label1}
            </FormLabel>
            <Input
              size="md"
              variant="outline"
              border="1px solid #ccc"
              focusBorderColor="#481053"
              borderRadius="14px"
              type="text"
              placeholder={placeholder}
              value={value1}
              onBlur={onBlur}
              onChange={(e) => onChange1(e, i)}
              required={required}
            />
          </FormControl>

          <FormControl
            w={{ base: "100%", md: "100%", lg: "48%" }}
            height={{ base: "100%", md: "100%", lg: "100px" }}
          >
            <FormLabel
              fontSize="14px"
              fontWeight="normal"
              h="20px"
              color="#515262"
              style={{ textTransform: "uppercase" }}
            >
              {label2}
            </FormLabel>
            <Textarea
              size="md"
              variant="outline"
              border="1px solid #ccc"
              focusBorderColor="#481053"
              borderRadius="14px"
              type={type ? type : "text"}
              placeholder={placeholder2 ? placeholder2 : placeholder}
              value={value2}
              onBlur={onBlur}
              onChange={(e) => onChange2(e, i)}
              required={required}
            />
          </FormControl>
          {array.length > 1 && (
            <Button
              onClick={() => {
                array.length > 1 && i !== 0 && handleDelete(i);
              }}
              position="absolute"
              top="0"
              colorScheme="red"
              variant="ghost"
              right="0"
            >
              X
            </Button>
          )}
        </Flex>
      ))}
    </Fragment>
  );
}
