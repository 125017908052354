import React, { Fragment, useState } from "react";
import DashboardReusable from "../ui/DashboardReusable";
import ReusableSubHeader from "../ui/ReusableSubheader";
import ReportDetailContainer from "./ReportDetailContainer";
import { Box } from "@chakra-ui/react";
import Loader from "../ui/Loader";

export default function ReportDetail({}) {
  const [progress, setProgress] = useState(0)
  return (
    <Fragment>
    <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable path="profile">
        <Box h="115vh" overflow="scroll">
          <ReusableSubHeader text="Report Details" />
          <ReportDetailContainer setProgress={setProgress} />
        </Box>
      </DashboardReusable>
    </Fragment>
  );
}
