import React from "react";
import { Heading } from "@chakra-ui/react";

export default function ReusableSubHeader({ text }) {
  return (
    <div className="w-full px-4 lg:px-10">
      <Heading
        as="h2"
        fontSize={{ base: "10px", md: "10px", lg: "18px" }}
        fontWeight="medium"
        pt="1.5rem"
        pb="0px"
      >
        {text}
      </Heading>
    </div>
  );
}
