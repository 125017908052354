import React from "react";
import ReusableTitle from "../../components/ui/ReusableTitle";

export default function UserTitle() {
  return (
    <ReusableTitle
      text1="NAME"
      text2="MEMBER ID"
      text3="DEPARTMENT"
      text4="DESIGNATION"
      text5="PHONE"
    />
  );
}
