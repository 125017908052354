import React, { Fragment } from "react";
import DashboardReusable from "../../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../../components/ui/ReusableSubheader";
import UserDetailContainer from "./UserDetailContainer";
import Loader from "../../../components/ui/Loader";

export default function UserDetail({ user, progress, setProgress }) {
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
        path="directorate-profile"
      >
        <ReusableSubHeader text="User details" />
        <UserDetailContainer user={user} />
      </DashboardReusable>
    </Fragment>
  );
}
