import React from "react";
import { Textarea, Flex } from "@chakra-ui/react";

export default function ReusableAddComment({ value, onChange, placeholder }) {
  return (
    <Flex w="100%" align="end" justify="end">
      <Flex w="95%" mx="auto">
        <Textarea
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          placeholder={placeholder ? placeholder : "Enter Comment here"}
          value={value}
          onChange={onChange}
          className="input"
          bg="white"
        />
      </Flex>
    </Flex>
  );
}
