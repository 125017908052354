export function sortData(data) {
  const mapped = data.map((item, i) => {
    return { i, value: item.updated_at };
  });

  mapped.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });

  return mapped.map((v) => data[v.i]);
}
