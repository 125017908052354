import React from "react";
import {
  Card,
  CardBody,
  Stack,
  Heading,
  Circle,
  Text,
  Box,
} from "@chakra-ui/react";
import Icon from "./Icon";
import ImageComponent from "./Image";

export default function ReportCard({ src, title, text }) {
  return (
    <Card
      direction="row"
      display="flex"
      align="center"
      justify="start"
      px="1rem"
      variant="elevated"
      borderRadius="0.75rem"
      cursor="pointer"
    >
      <Circle size="40px" bg="rgba(48, 79, 253, 0.1)" color="white">
        <Box width="20px">
          <ImageComponent height="20px" alt="report card" src={src} />
        </Box>
      </Circle>
      <Stack>
        <CardBody>
          <Heading
            fontSize={{ base: "8px", md: "8px", lg: "14px" }}
            fontWeight="normal"
            color="#8A9099"
            as="h4"
          >
            {title}
          </Heading>
          <Text fontSize="25px" fontWeight="bold" py="2">
            {text}
          </Text>
        </CardBody>
      </Stack>
    </Card>
  );
}
