import React from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function SubHeader({ setShowReportPopup }) {
  return (
    <Flex px={{ lg: "2.5rem", base: "0.5rem", md: "0.5rem" }} py="1.5rem">
      <Heading
        color="#3F434A"
        fontSize={{ base: "12px", md: "12px", lg: "24px" }}
        fontWeight="medium"
        as="h3"
      >
        Dashboard
      </Heading>
      <Spacer />
      <Menu>
        <MenuButton
          bg="brand.900"
          color="white"
          _hover={{ bg: "#C6A533", text: "brand.900" }}
          rightIcon={<ChevronDownIcon />}
          _expanded={{ bg: "#C6A533" }}
          as={Button}
          fontSize={{ base: "12px", md: "12px", lg: "16px" }}
          size={{ base: "sm", md: "sm", lg: "md" }}
        >
          Submit Report
        </MenuButton>
        <MenuList>
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
          >
            <Link to="/report/submit-report" className="report-btn">
              Department Assessment Form
            </Link>
          </MenuItem>
          {false && (
            <MenuItem>
              <Link to="" className="report-btn">
                Report Two
              </Link>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
}
