import React, { useEffect } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import PastorDashboardReport from "./PastorDashboardReport";
import PastorDashboardSubmission from "./PastorDashboardSubmission";

export default function PastorDashboard() {
  return (
    <DashboardReusable
      dashboard="pastor-dashboard"
      report="pastor-report"
      user="pastor-user"
      profile="pastor-profile"
      show={true}
      path="pastor-profile"
    >
      <ReusableSubHeader text="Dashboard" />
      <PastorDashboardReport />
      <PastorDashboardSubmission />
    </DashboardReusable>
  );
}
