import React from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import PastorReportDetailContainer from "./PastorReportDetailContainer";

import { Box } from "@chakra-ui/react";

export default function PastorReportDetail() {
  return (
    <DashboardReusable
      dashboard="pastor-dashboard"
      report="pastor-report"
      user="pastor-user"
      profile="pastor-profile"
      show={true}
    >
      <Box h="115vh" overflow="scroll">
        <ReusableSubHeader text="Technology Department" />
        <PastorReportDetailContainer />
      </Box>
    </DashboardReusable>
  );
}
