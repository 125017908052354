import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import ReportFilter from "./ReportFilter";
import ReportList from "./ReportList";
import ReportTitle from "./ReportTitle";
import ReportFooter from "./ReportFooter";
import WhiteBG from "../ui/WhiteBG";
import { useEffect } from "react";
import { getDashboard, getUsers } from "../../util/http";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";
import { sortData } from "../../util/lib";

export default function ReportContainer({ setProgress }) {
  const [allReports, setAllReports] = useState([]);
  const [user, setUser] = useState("");
  const [slicedReport, setSlicedReport] = useState([]);

  const [loading, setLoading] = useState(false);
  const ctx = useContext(AuthContext);

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");

  const compareFn = (a, b) => (a > b ? 1 : 0);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getDashboard(token);

        const result = sortData(response?.reports);

        setAllReports(result.reverse());

        setProgress(80);
        setProgress(100);
        setLoading(false);
        setUser(ctx.user);
      } catch (error) {
        setProgress(100);
        setLoading(false);
      }
    }
    fetchData();
  }, [token, ctx]);

  const [totalReport, setTotalReport] = useState(0);

  useEffect(() => {
    setTotalReport(allReports?.length);
  }, [allReports]);

  const [currentPage, setCurrentPage] = useState(1);
  const [reportPerPage, setreportPerPage] = useState(10);

  const lastreportIndex = currentPage * reportPerPage;
  const firstreportIndex = lastreportIndex - reportPerPage;
  useEffect(() => {
    const reportData = allReports.slice(firstreportIndex, lastreportIndex);
    setSlicedReport(reportData);
  }, [firstreportIndex, lastreportIndex, allReports]);

  return (
    <Box
      w="100%"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      // mt="1.5rem"
    >
      <WhiteBG>
        <ReportFilter setReport={setAllReports} report={allReports} />
        <Flex
          w="100%"
          direction="column"
          gap="1.5rem"
          p={{ base: "0.5rem", md: "0.5rem", lg: "1.5rem" }}
          maxHeight="97vh"
        >
          <ReportTitle />
          <ReportList reports={slicedReport} user={user} />
          <ReportFooter
            setItemPerPage={setreportPerPage}
            total={totalReport}
            itemPerPage={reportPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setItem={setSlicedReport}
            items={allReports}
          />
        </Flex>
      </WhiteBG>
    </Box>
  );
}
