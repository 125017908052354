import React from "react";
import Report from "../directorate/report/Report";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function DirectorateReportPage() {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
      return;
    }
  }, [ctx.isLoggedIn, navigate]);

  // useEffect(() => {
  //   if (
  //     role?.toLowerCase() !== "directorate" ||
  //     role?.toLowerCase() !== "pastor"
  //   ) {
  //     navigate("/login");
  //     return;
  //   }
  // }, [role, navigate]);
  return <Report />;
}
