import React, { Fragment, useState } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import UserHeader from "./UserHeader";
import UserContainer from "./UserContainer";
import CreateUser from "./CreateUser";
import { useDisclosure } from "@chakra-ui/react";
import Loader from "../../components/ui/Loader";

export default function User() {
  const [progress, setProgress] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
        path="directorate-profile"
      >
        <UserHeader open={onOpen} />
        <UserContainer setProgress={setProgress} />
      </DashboardReusable>
      <CreateUser
        progress={progress}
        setProgress={setProgress}
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Fragment>
  );
}
