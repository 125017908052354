import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthComponent from "../ui/AuthComponent";
import { Flex, Button, useToast } from "@chakra-ui/react";

import Loader from "../ui/Loader";
import PasswordValidator from "../../validation/PasswordValidator";
import PasswordValidation from "../../validation/PasswordValidation";
import {
  validateIncludes,
  validateLength,
  validateLetter,
  validateStrength,
} from "../../util/validator";
import PasswordInput from "../ui/PasswordInput";
import { resetPassword } from "../../util/http";

export default function NewPassword() {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isUppercase, setIsUppercase] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isEightChar, setIsEightChar] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);

  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const toast = useToast();

  function passwordHandler(e) {
    setShowValidation(true);
    const pass = e.target.value;

    // password length
    if (validateLength(pass)) {
      setIsEightChar(true);
    } else {
      setIsEightChar(false);
    }

    if (validateLetter(pass)) {
      // setIsNumber(true);
      setIsUppercase(true);
    } else {
      // setIsNumber(false);
      setIsUppercase(false);
    }

    // password contain special characters
    if (validateIncludes(pass, "@")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "!")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "#")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "$")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "+")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "-")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "%")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "^")) {
      setIsSymbol(true);
    } else if (validateIncludes(pass, "_")) {
      setIsSymbol(true);
    } else {
      setIsSymbol(false);
    }

    if (validateStrength(pass) < 20) {
      setError(true);
      setFormIsValid(false);
    } else if (validateStrength(pass) > 20 && validateStrength(pass) <= 40) {
      setError(true);
      setWarning(true);
      setFormIsValid(false);
    } else if (validateStrength(pass) > 40) {
      setError(true);
      setWarning(true);
      setSuccess(true);
      setPassword(pass);
      setFormIsValid(true);
    } else {
      setError(true);
      setWarning(false);
      setSuccess(false);
      setFormIsValid(false);
    }
  }

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  }

  async function resetHandler(e) {
    e.preventDefault();

    const resetEmail = localStorage.getItem("user-email");
    const resetToken = localStorage.getItem("reset-token");

    if (password !== confirmPassword) {
      showToast("Password do not match", "", "error");
      setPasswordMatch(false);
      return;
    }
    const data = {
      password,
      password_confirmation: confirmPassword,
      email: resetEmail,
      reset_token: resetToken,
    };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);

      const response = await resetPassword(data);
      localStorage.removeItem("reset-token");
      localStorage.removeItem("user-email");
      showToast("Password successfully reset", "", "success");
      navigate("/login");
      setProgress(80);
      setProgress(100);

      setLoading(false);
      navigate("/login");
    } catch (error) {
      // console.log(error);
      setProgress(100);
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
      setLoading(false);
    }
  }

  function toggleHandler() {
    if (type === "password") {
      setType("text");
      setIcon(false);
    } else {
      setType("password");
      setIcon(true);
    }
  }
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent
        heading="Reset Password"
        text="Remember your login details?"
        path="login"
        linkText="Sign In"
        blue={true}
      >
        <form
          onSubmit={resetHandler}
          className="w-[90%] lg:w-[400px] mx-auto flex flex-col gap-4"
        >
          <PasswordInput
            toggleHandler={toggleHandler}
            label="New Password"
            type={type}
            icon={icon}
            placeholder="************************"
            onChange={passwordHandler}
          />

          <PasswordInput
            toggleHandler={toggleHandler}
            label="Confirm new password"
            type={type}
            icon={icon}
            placeholder="************************"
            onChange={(e) => setConfirmPassword(e.target.value)}
            isInvalid={!passwordMatch}
          />

          {showValidation && (
            <PasswordValidator
              error={error}
              warning={warning}
              success={success}
            />
          )}
          {showValidation && (
            <PasswordValidation
              isEightChar={isEightChar}
              isSymbol={isSymbol}
              isUppercase={isUppercase}
            />
          )}

          <Flex w="100%" alignItems="center" justify="space-between">
            <Button
              w="100%"
              bg="brand.900"
              color="white"
              borderRadius="14px"
              variant="solid"
              isLoading={loading}
              loadingText="Processing"
              type="submit"
              isDisabled={!formIsValid}
            >
              Reset password
            </Button>
          </Flex>
        </form>
      </AuthComponent>
    </Fragment>
  );
}
