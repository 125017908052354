import React from "react";
import { Text } from "@chakra-ui/react";

export default function SubmissionTitleText({ text, display }) {
  return (
    <Text
      w={{ base: "22%", md: "22%", lg: "20%" }}
      fontWeight="medium"
      color="#8A9099"
      textAlign="center"
      fontSize={{ base: "10px", md: "10px", lg: "16px" }}
      className="submission-title"
      display={display ? display : "block"}
    >
      {text}
    </Text>
  );
}
