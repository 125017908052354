import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import WhiteBG from "../../components/ui/WhiteBG";
import DetailHeader from "../../components/ui/DetailHeader";
import PastorDetail from "./PastorDetail";

export default function PastorReportDetailContainer() {
  return (
    <Box w="100%" px="10" mt="1.5rem">
      <WhiteBG>
        <Flex direction="column" gap="2rem">
          <DetailHeader text="Departmental Assessment & Service Details Form" />
          <PastorDetail />
        </Flex>
      </WhiteBG>
    </Box>
  );
}
