import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { CheckIcon, InfoIcon } from "@chakra-ui/icons";
import { approveReport } from "../../util/http";
import { useNavigate } from "react-router-dom";

export default function ReportActions({
  createCommentHandler,
  report,
  showToast,
}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  async function approveReportHandler() {
    const data = {
      status: "approved",
    };
    try {
      const response = await approveReport(token, data, report?.id);
      showToast("Report approved", "", "success");
      window.location.reload();

      

    } catch (error) {
      showToast(
        error?.response?.data.message || "Something went wrong",
        "",
        "error"
      );
      // console.log(error);
    }
  }

  return (
    <Flex align="center" justify="end" gap="1rem">
      <Button
        variant="ghost"
        color="success.900"
        leftIcon={<CheckIcon />}
        colorScheme="green"
        isDisabled={report?.status === "approved"}
        onClick={() => approveReportHandler()}
      >
        Approve
      </Button>{" "}
      <Button
        isDisabled={
          report?.status === "approved" ||
          report?.status === "queried" ||
          report?.status === "replied"
        }
        variant="ghost"
        color="error.900"
        leftIcon={<InfoIcon />}
        colorScheme="red"
        onClick={() =>
          report?.comments?.length < 1
            ? createCommentHandler()
            : showToast("Report queried already", "", "warning")
        }
      >
        Query
      </Button>
    </Flex>
  );
}
