import React from "react";
import SubmissionHeader from "../../components/dashboard/SubmissionHeader";
import { Box, Flex } from "@chakra-ui/react";
import WhiteBG from "../../components/ui/WhiteBG";
import PastorSubmissionTitle from "./PastorSubmissionTitle";
import SubmissionList from "./SubmissionList";

export default function PastorDashboardSubmission() {
  return (
    <Box w="100%" mt="1.5rem" bg="transparent" px="2.5rem">
      <WhiteBG>
        <SubmissionHeader />
        <Flex direction="column" gap="2rem" mt="1.7rem">
          <PastorSubmissionTitle />
          <SubmissionList />
        </Flex>
      </WhiteBG>
    </Box>
  );
}
