import React from "react";
import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function DoubleInput({
  label1,
  value1,
  onChange1,
  placeholder,
  placeholder2,
  label2,
  value2,
  onChange2,
  register1,
  register2,
  type,
  max,
  border,
  readOnly,
  required = false,
}) {


  return (
    <Flex
      align="center"
      direction={{ base: "column", md: "column", lg: "row" }}
      justify="space-between"
      gap="2rem"
    >
      <FormControl
        isRequired={required}
        w={{ base: "100%", md: "100%", lg: "50%" }}
      >
        <FormLabel
          h={{ base: "100%", md: "100%", lg: "20px" }}
          autoCapitalize="on"
          fontSize="14px"
          fontWeight="normal"
          color="#515262"
          style={{ textTransform: "uppercase" }}
        >
          {label1}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border={border ? border : "1px solid #ccc"}
          focusBorderColor="#481053"
          borderRadius="14px"
          type={type ? type : "text"}
          placeholder={placeholder}
          {...register1}
          value={value1}
          onChange={onChange1}
        />
      </FormControl>
      <FormControl
        isRequired={required}
        w={{ base: "100%", md: "100%", lg: "50%" }}
      >
        <FormLabel
          autoCapitalize="true"
          fontSize="14px"
          fontWeight="normal"
          h={{ base: "100%", md: "100%", lg: "20px" }}
          color="#515262"
          style={{ textTransform: "uppercase" }}
        >
          {label2}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          type={type ? type : "text"}
          maxLength={max ? max : ""}
          placeholder={placeholder2 ? placeholder2 : placeholder}
          value={value2}
          onChange={onChange2}
          readOnly={readOnly ? readOnly : false}
          {...register2}
        />
      </FormControl>
    </Flex>
  );
}
