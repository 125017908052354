import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import AuthProvider from "./util/authContext";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#C395BB",
      900: "#481053",
    },
    gray: {
      100: "#B2B3B3",
      900: "#828282",
    },
    success: {
      100: "#90EE90",
      900: "#00AC45",
    },
    error: {
      100: "#ffcccb",
      900: "#FF3436",
    },
    warning: {
      100: "#FFB649",
      900: "#FFB649",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
