import React, { Fragment, useState } from "react";
import SubHeader from "./SubHeader";
import DashboardReport from "./DashboardReport";
import DashboardSubmissions from "./DashboardSubmissions";
import ReportPopup from "../ui/ReportPopup";

export default function DashboardCenter({
  report,
  user,
  setReport,
  allReports,
  setAllReports,
}) {
  const [showReportPopup, setShowReportPopup] = useState(false);
  return (
    <Fragment>
      <div className="flex-1 bg-[#F6F6F6] h-[130vh]">
        <SubHeader setShowReportPopup={setShowReportPopup} />
        <DashboardReport report={report} />
        <DashboardSubmissions
          setReport={setAllReports}
          user={user}
          reports={allReports}
        />
      </div>
      {showReportPopup && <ReportPopup />}
    </Fragment>
  );
}
