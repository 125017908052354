import React from "react";
import Profile from "../components/profile/Profile";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ProfilePage() {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
      return;
    }
  }, [ctx.isLoggedIn, navigate]);
  return <Profile />;
}
