import React from "react";
import SubmitReport from "../components/SubmitReport/SubmitReport";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function SubmitReportPage() {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
      return;
    }
  }, [ctx.isLoggedIn, navigate]);
  return <SubmitReport />;
}
