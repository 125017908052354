import React from "react";
import SubmissionHeader from "./SubmissionHeader";
import SubmissionTitle from "./SubmissionTitle";
import SubmissionList from "./SubmissionList";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function DashboardSubmissions({ reports, user, setReport }) {
  const navigate = useNavigate();
  return (
    <div className="w-full mt-6 bg-transparent px-2 lg:px-10">
      <div className="w-full max-h-[87vh] flex flex-col gap-6 h-full p-6 bg-white rounded-xl">
        <SubmissionHeader reports={reports} setReport={setReport} />
        <SubmissionTitle />
        <SubmissionList user={user} reports={reports} />
        <Flex mt="1rem" align="center" justify="end">
          <Button
            onClick={() => navigate("/report")}
            bg="#f8f8f8"
            color="brand.900"
          >
            View All
          </Button>
        </Flex>
      </div>
    </div>
  );
}
