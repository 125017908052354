import React, { Fragment, useEffect, useState } from "react";
import DashboardReusable from "../ui/DashboardReusable";
import SubHeader from "./SubHeader";
import ReportForm from "./ReportForm";
import { Box } from "@chakra-ui/react";
import Loader from "../ui/Loader";
import { getUsers } from "../../util/http";

export default function SubmitReport() {
  const id = localStorage.getItem("memberID");
  const token = localStorage.getItem("token");

  const [user, setUser] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUsers(token, id);
        setUser(response?.user[0]);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token, id]);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable path="profile">
        <Box h="120vh" overflow="scroll">
          <SubHeader />
          <ReportForm
            user={user}
            progress={progress}
            setProgress={setProgress}
          />
        </Box>
      </DashboardReusable>
    </Fragment>
  );
}
