import { getAllUsers } from "../../util/http";
import ReusableUserFilter from "../ui/ReusableUserFilter";

export default function UserFilter({ setUsers }) {
  const token = localStorage.getItem("token");

  async function searchHandler(e) {
    const response = await getAllUsers(token);
    const filteredItem = response?.users?.filter((el) =>
      el.first_name
        ?.toLowerCase()
        .includes(
          e.target.value.trim().toLowerCase() ||
            el.last_name
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase())
        )
    );

    setUsers(filteredItem);
  }
  return <ReusableUserFilter onChange={searchHandler} />;
}
