import React from "react";
import { Link } from "react-router-dom";

export default function ReportPopup() {
  return (
    <div className="w-[212px] h-[120px] bg-white rounded-xl absolute right-[2.5%] top-[20%] p-4 flex flex-col items-start justify-center">
      <Link to="/submit-report" className="report-btn">
        Report One
      </Link>
      <Link to="" className="report-btn">
        Report Two
      </Link>
    </div>
  );
}
