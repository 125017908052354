import React, { useState } from "react";
import { Flex, Button } from "@chakra-ui/react";
import ReusableSelect from "../../components/ui/ReusableSelect";
import DoubleInputState from "../../components/ui/DoubleInputState";
import ReusableComment from "../../components/ui/ReusableComment";
import ReusableReplyCommentBtn from "../../components/ui/ReusableReplyCommentBtn";
import ReusableAddComment from "../../components/ui/ReusableAddComment";
import ReusableTextarea from "../../components/ui/ReusableTextarea";

import { CheckIcon, InfoIcon } from "@chakra-ui/icons";

export default function PastorDetail() {
  const [showComment1, setShowComment1] = useState(false);
  const [showComment2, setShowComment2] = useState(false);
  const [showComment3, setShowComment3] = useState(false);
  const [showComment4, setShowComment4] = useState(false);
  const [showComment5, setShowComment5] = useState(false);
  return (
    <Flex direction="column" gap="2rem">
      <ReusableSelect />
      <DoubleInputState
        label1="No. of Department Members"
        label2="No. of Members in Service"
        placeholder="e.g 3"
      />{" "}
      <DoubleInputState
        label1="Reason for being Absent"
        label2=""
        placeholder="Full Name"
        placeholder2="Reason"
      />
      <ReusableComment
        comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  "
        title="Pastor Toba Oluwaponmile"
        date="16/09/2022 11:45 am"
        image=""
      />
      <ReusableReplyCommentBtn
        onClick={() => setShowComment1((prev) => !prev)}
        title="REPLY COMMENT"
      />
      {showComment1 && <ReusableAddComment />}
      {showComment1 && <ReusableReplyCommentBtn title="SAVE COMMENT" />}
      <DoubleInputState
        label1="Those Sick, Travelled etc"
        label2=""
        placeholder="Full Name"
        placeholder2="Reason"
      />
      <ReusableTextarea
        onClick={() => setShowComment2((prev) => !prev)}
        visible={true}
        label="Points of Excellence"
      />
      {showComment2 && <ReusableAddComment />}
      {showComment2 && <ReusableReplyCommentBtn title="SAVE COMMENT" />}
      <ReusableTextarea label="Needs for Improvement (NFIs)" bg="#E8E9EB" />
      <ReusableAddComment placeholder="Pastor reply" />
      <ReusableReplyCommentBtn title="SAVE COMMENT" />
      <ReusableAddComment placeholder="HOD or AST HOD Reply" />
      <ReusableReplyCommentBtn title="REPLY COMMENT" />
      <ReusableTextarea
        onClick={() => setShowComment3((prev) => !prev)}
        visible={true}
        label="Any other concern or observations"
      />
      {showComment3 && <ReusableAddComment />}
      {showComment3 && <ReusableReplyCommentBtn title="SAVE COMMENT" />}
      <ReusableComment
        comment="reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
  pariatur."
        title="Pastor Toba Oluwaponmile"
        date="16/09/2022 11:45 am"
        image=""
      />
      {showComment3 && <ReusableReplyCommentBtn title="SAVE COMMENT" />}
      <ReusableTextarea
        onClick={() => setShowComment4((prev) => !prev)}
        visible={true}
        label="Assitant HOD’s remarks"
      />
      {showComment4 && <ReusableAddComment />}
      {showComment4 && <ReusableReplyCommentBtn title="SAVE COMMENT" />}
      <ReusableTextarea
        onClick={() => setShowComment5((prev) => !prev)}
        visible={true}
        label="HOD’s remarks"
      />
      {showComment5 && <ReusableAddComment />}
      {showComment5 && <ReusableReplyCommentBtn title="SAVE COMMENT" />}
      <ReusableTextarea label="Director’s remarks" />
      <ReusableTextarea label="official remarks - resident pastor" />
      <Flex align="center" justify="end" gap="1rem">
        <Button
          variant="ghost"
          color="success.900"
          leftIcon={<CheckIcon />}
          colorScheme="green.900"
        >
          Approve
        </Button>{" "}
        <Button
          variant="ghost"
          color="error.900"
          leftIcon={<InfoIcon />}
          colorScheme="red.900"
        >
          Query
        </Button>
      </Flex>
    </Flex>
  );
}
