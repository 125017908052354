import React, { useState } from "react";
import ReusableCreateUser from "../../directorate/ui/ReusableCreateUser";
import PastorCreateUserForm from "./PastorCreateUserForm";

import { useToast } from "@chakra-ui/react";

export default function PastorCreateUser({
  isOpen,
  onOpen,
  onClose,
  progress,
  setProgress,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [image, setImage] = useState("");
  const btnRef = React.useRef();

  const [loading, setLoading] = useState();

  const toast = useToast();

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 5000,
      isClosable: true,
    });
  }

  async function createUserHandler() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phone,
      department,
      designation,
      image,
    };
    // console.log(data);

    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = "";
      showToast("Successful", "User successfully created", "success");
      setProgress(80);
      setProgress(100);
    } catch (error) {
      setProgress(100);
      // console.log(error);
      showToast("ERROR", "Something went wrong", "error");
      setLoading(false);
    }
  }
  return (
    <ReusableCreateUser
      btnRef={btnRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClick={createUserHandler}
    >
      <PastorCreateUserForm
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        setImage={setImage}
        setDepartment={setDepartment}
        setDesignation={setDesignation}
        progress={progress}
        setProgress={setProgress}
      />
    </ReusableCreateUser>
  );
}
