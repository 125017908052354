import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

import logo from "../../images/ehcc_logo_colour 1.png";
import { useNavigate } from "react-router-dom";
import dashboardImg from "../../images/Grid.png";
import reportImg from "../../images/ClipboardText.png";
import group from "../../images/UsersThree.png";
import userImg from "../../images/User.png";
import logoutImg from "../../images/logout.png";
import { Box, Flex, useToast } from "@chakra-ui/react";
import ImageComponent from "../ui/Image";
import NavItem from "../ui/NavItem";

export default function MobileHeader({
  onClose,
  isOpen,
  dashboard,
  report,
  user,
  show,
  profile,
  logoutHandler,
}) {
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent p="0px">
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          <Box w="9.75rem" mx="auto">
            <ImageComponent src={logo} alt="dashboard" height="7.69rem" />
          </Box>
        </DrawerHeader>
        <DrawerBody>
          <Flex
            w="100%"
            flexDirection="column"
            // h="130vh"
            py="2rem"
            gap="3rem"

            // display={{ base: "none", md: "none", lg: "flex" }}
          >
            <div className="w-full flex flex-col">
              <NavItem
                src={dashboardImg}
                alt="dashboard"
                height="16px"
                path={dashboard ? dashboard : ""}
                title="dashboard"
              />
              <NavItem
                src={reportImg}
                alt="report"
                height="17.25px"
                path={report ? report : "report"}
                title="report"
              />

              {show && (
                <NavItem
                  height="17.25px"
                  src={group}
                  alt="group"
                  path={user ? user : "user"}
                  title="user"
                />
              )}
              <NavItem
                src={userImg}
                alt="user"
                height="17.25px"
                title="profile"
                path={profile ? profile : "profile"}
              />
            </div>
            <div onClick={logoutHandler} className="mt-20">
              <button className="flex items-center gap-4 px-6 py-5 text-[15px] text-[#FF0000] capitalize font-medium">
                <Box w="15px">
                  <ImageComponent
                    src={logoutImg}
                    alt="logout"
                    height="17.25px"
                  />
                </Box>
                logout
              </button>
            </div>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
