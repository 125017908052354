import React from "react";
import WhiteBG from "../../components/ui/WhiteBG";
import { Box, Flex } from "@chakra-ui/react";
import ReportFilter from "../../components/report/ReportFilter";
import ReusableTitle from "../../components/ui/ReusableTitle";
import PastorReportList from "./PastorReportList";
import ReportFooter from "../../components/report/ReportFooter";

export default function PastorReportContainer() {
  return (
    <Box w="100%" px="10" mt="1.5rem">
      <WhiteBG>
        <ReportFilter />
        <Flex
          w="100%"
          direction="column"
          gap="1.5rem"
          p="1.5rem"
          maxHeight="650px"
        >
          <ReusableTitle
            text1="NAME"
            text2="TIME / DATE"
            text3="DEPARTMENT"
            text4="STATUS"
            text5="DESIGNATION"
          />
          <PastorReportList />
          <ReportFooter />
        </Flex>
      </WhiteBG>
    </Box>
  );
}
